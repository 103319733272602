import React, { useEffect, useState } from "react"
import { Container, Card, Button, Row, Col } from "react-bootstrap"
import { sectionSpace } from "../constants"
import { LoadingView } from "../utils/components"
import EventList from "./EventList"
import { fetchEvents } from "../components/fetchEvents"

function UpcomingEvents({
  events,
  bgColor,
  isLoading,
  containerId,
  title,
  subtitle,
  eventCategory,
  pagingInfo,
}) {
  function openLink(link) {
    window.open(link, "_blank")
  }
  return (
    <div style={{ backgroundColor: bgColor, width: "100%", maxWidth: "100%" }}>
      <Container
        className={"pb-5 pt-3 mr-20 ml-20"}
        id={containerId}
      >
        <h1 className="text-center mb-2 mt-3">{title}</h1>
        <h6 className="text-center mt-2">{subtitle}</h6>
        <div className={sectionSpace} />
        <LoadingView
          isLoading={isLoading}
          view={
            <EventList
              events={events}
              title={title}
              eventCategory={eventCategory}
              openLink={openLink}
            />
          }
        />
        {pagingInfo.has_next && (
          <div className="text-center">
            <Button
              onClick={() => openLink(`/all-events/${eventCategory}`)}
              variant="primary"
              className="text-white my-3 mx-auto"
            >
              View More
            </Button>
          </div>
        )}
      </Container>
    </div>
  )
}

function UpcomingEventsContainer({
  eventCategory,
  bgColor,
  containerId,
  title,
  subtitle,
}) {
  const [events, setEvents] = useState([])
  const [pagingInfo, setPagingInfo] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)

    fetchEvents(eventCategory)
      .then((fetchedEvents) => {
        setEvents(fetchedEvents.events)
        setPagingInfo(fetchedEvents.paging_info)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
      })
  }, [])

  return (
    <UpcomingEvents
      bgColor={bgColor}
      containerId={containerId}
      title={title}
      subtitle={subtitle}
      eventCategory={eventCategory}
      events={events}
      isLoading={isLoading}
      pagingInfo={pagingInfo}
    />
  )
}

export default UpcomingEventsContainer
