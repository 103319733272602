import { Image } from "react-bootstrap"
import virtualImg from "../../assets/images/virtual.png"
import { Slide } from "react-awesome-reveal"

export default function VirtualCard() {
  return (
    <div
      id={"virtualCard"}
      className={"mb-5 p-5"}
      style={{ backgroundColor: "#f5f5f5", width: "100%" }}
    >
      <div className="row px-2 justify-content-center">
        <div className="col-md-6 pt-3 my-auto">
          <Slide direction={"left"}>
            <h1>Virtual USD Card</h1>
            <p className="fs-6">
              Introducing the Virtual USD Card – your passport to borderless
              spending. With seamless transactions in USD, it offers global
              convenience without currency exchange hassles. Enjoy real-time
              security features like transaction alerts and instant card
              freezing. Manage your finances effortlessly with detailed insights
              and customizable spending limits. Experience ease, security, and
              flexibility with the Virtual USD Card – where the world is yours
              to explore.
            </p>
          </Slide>
        </div>
        <div className="col-md-6 pt-5">
          <Slide direction={"right"}>
            <Image
              fluid={true}
              src={virtualImg}
            />
          </Slide>
        </div>
      </div>
    </div>
  )
}
