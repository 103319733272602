import React from "react"
import { Button, Card, Carousel, Col, Row } from "react-bootstrap"
import { FaExternalLinkAlt } from "react-icons/fa"
import { displayText } from "../../../utils/functions"
import { DisplayText } from "../../../utils/components"

function ContestantListItem({
  contestant,
  index,
  period,
  voteCallback,
  availableVotes = 0,
}) {
  return (
    <Card>
      <Carousel
        draggable={true}
        interval={null}
      >
        {contestant?.images?.map((image, I) => (
          <Carousel.Item
            key={I}
            draggable={true}
            interval={null}
          >
            <Card.Img
              variant={"top"}
              className="d-block w-100"
              src={image.uri}
              alt={contestant.title}
            />
          </Carousel.Item>
        ))}
      </Carousel>

      <Card.Body>
        <Card.Title
          as={"h2"}
          className={"text-primary mb-3"}
        >
          {contestant.title}
        </Card.Title>
        <Card.Subtitle as={"h6"}>
          <DisplayText text={contestant.bio} />
        </Card.Subtitle>
        {period.public_stats && (
          <div className={"mt-5"}>
            <Row className={"text-center"}>
              <Col
                xs={3}
                className={"my-auto"}
              >
                <h4>{index + 1}</h4>
                <p>Position</p>
              </Col>
              <Col
                xs={6}
                className={"my-auto"}
              >
                <strong>Stats for {contestant.title}</strong>
              </Col>
              <Col
                xs={3}
                className={"my-auto"}
              >
                <h4>{contestant.votes}</h4>
                <p>Votes</p>
              </Col>
            </Row>
          </div>
        )}
        {contestant?.links?.length > 0 && (
          <div className={"my-3"}>
            <h5>Links for {contestant.title}</h5>
            <hr />
            {contestant.links.map((link, J) => {
              const key = Object.keys(link)[0]

              return (
                <Button
                  key={J}
                  variant={"outline-primary"}
                  className={"me-2 mb-3"}
                  onClick={() => {
                    window.open(contestant[link[key]], "_blank")
                  }}
                >
                  {contestant[key]}
                  <FaExternalLinkAlt className={"ms-2"} />
                </Button>
              )
            })}
          </div>
        )}
      </Card.Body>
      <Card.Footer className={"py-3"}>
        <Button
          className={"w-100"}
          variant={"primary"}
          disabled={availableVotes === 0}
          onClick={() => {
            voteCallback(contestant)
          }}
        >
          Vote for {contestant.title}
        </Button>
      </Card.Footer>
    </Card>
  )
}

export default ContestantListItem
