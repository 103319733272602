import {Container} from "react-bootstrap";

export default function OrganizerAgreement() {
	return (
		<Container>
			<iframe
				title={'Ventix Organizer'}
				src="https://docs.google.com/document/d/e/2PACX-1vSF-n90uSH2mhuJTX3c6vgec2JbFODivD_RIoM8hn9jCSUCyOy2uvKpg9QBfzuaHj30m9KJPE2AMUjy/pub?embedded=true"
				width={window.outerWidth}
				height={window.outerHeight}
				loading={'eager'}
        align="center"
			/>
		</Container>
	)
}