import { Card, Col, Container, Image, Row } from "react-bootstrap"
import phones from "../assets/images/why.png"
import { FaMobileAlt } from "react-icons/fa"
import { MdQuickreply, MdOutlineSecurity } from "react-icons/md"
import { AiFillControl, AiFillCheckCircle } from "react-icons/ai"
import { SiSpringsecurity } from "react-icons/si"
import { constants } from "../constants"
const Organizers = [
  {
    icon: MdQuickreply,
    title: "Efficient",
    text: "Easily set up ticketing for your event and let attendees immediately start purchasing using their mobile money accounts.",
  },
  {
    icon: MdOutlineSecurity,
    title: "Eliminate Ticket Fraud",
    text: "Every ticket sold is verifiable and can only be used once. You can also view the scan activity of every ticket.",
  },
  {
    icon: AiFillControl,
    title: "Fully Transparent",
    text: "You sell it, you set the price. You will see all tickets sold and how much you made from each sale.",
  },
]

const Attendees = [
  {
    icon: FaMobileAlt,
    title: "Easy to Use",
    text: "Get tickets to events from your phone. Use your mobile money account to buy tickets and get immediate access to them.",
  },
  {
    icon: SiSpringsecurity,
    title: "Safe & Secure",
    text: "Your tickets will never be lost even if you lose your phone. All you have to do is log into Ventix on any phone and your tickets will be right there.",
  },
  {
    icon: AiFillCheckCircle,
    title: "Authenticity Guaranteed",
    text: "Every ticket you purchase on Ventix is authentic and valid. Rest assured that your spot at the event is guaranteed.",
  },
]

export default function Why() {
  return (
    <div
      className={"container-bkg"}
      id={"why"}
    >
      <Container className={"pt-5 text-white"}>
        <h1 className={"text-center mt-5"}>Why {constants.appName}</h1>

        <Row>
          <Col
            md={"4"}
            className={"my-auto"}
          >
            <h4 className={"text-center"}>Event Organizers</h4>
            {Organizers.map((item, i) => {
              return (
                <div key={i}>
                  <Card className={"bg-transparent"}>
                    <Card.Body>
                      <Row>
                        <Col
                          xs={"3"}
                          className={"my-auto"}
                        >
                          <item.icon
                            className={"text-white icon-bkg-sm fs-1"}
                          />
                        </Col>
                        <Col
                          xs={"9"}
                          className={"my-auto text-white"}
                        >
                          <strong>{item.title}</strong>
                          <br />
                          <small>{item.text}</small>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </div>
              )
            })}
            <div className={"text-center mt-4"}>
              <h5>Check out our organizer website for more info</h5>
              <a
                href={"https://www.ventix.biz"}
                className={"text-white"}
                target={"_blank"}
              >
                Go to Organizer Website {">>"}
              </a>
            </div>
          </Col>
          <Col
            md={"4"}
            className={"my-auto"}
          >
            <Image
              fluid={true}
              src={phones}
            />
          </Col>
          <Col
            md={"4"}
            className={"my-auto"}
          >
            <h4 className={"text-center"}>Event Attendees</h4>
            {Attendees.map((item, i) => {
              return (
                <div key={i}>
                  <Card className={"bg-transparent"}>
                    <Card.Body>
                      <Row>
                        <Col
                          xs={"3"}
                          className={"my-auto"}
                        >
                          <item.icon
                            className={"text-white icon-bkg-sm fs-1"}
                          />
                        </Col>
                        <Col
                          xs={"9"}
                          className={"my-auto text-white"}
                        >
                          <strong>{item.title}</strong>
                          <br />
                          <small>{item.text}</small>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </div>
              )
            })}
          </Col>
        </Row>
      </Container>
    </div>
  )
}
