import { Container, Image } from "react-bootstrap"
import { Zoom } from "react-awesome-reveal"

import CashAccountImg from "../../assets/images/cashaccount.png"

export default function CashAccount() {
  return (
    <Container id={"cashAccount"}>
      <div className="row px-2 ">
        <div className="col-md-6 pt-5 my-auto">
          <Zoom direction={"left"}>
            <h1>Cash Account</h1>
            <p className="justify-center fs-6">
              Cash Account, your ultimate solution for seamless financial
              management on-the-go! effortless control over your finances with
              just a tap. With Cash Account, managing your money has never been
              easier. Whether you're tracking expenses, transferring funds, or
              monitoring your savings goals, our intuitive interface puts
              everything at your fingertips.
            </p>
          </Zoom>
        </div>
        <div className="col-md-6 mr-3 pt-5">
          <Zoom direction={"right"}>
            <Image
              fluid={true}
              src={CashAccountImg}
            />
          </Zoom>
        </div>
      </div>
    </Container>
  )
}
