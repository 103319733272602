import {
  Alert,
  Button,
  Card,
  Form,
  Image,
  InputGroup,
  ListGroup,
} from "react-bootstrap"
import afrimoney from "../assets/images/afrimoneylogo1.png"
import { formatMoney, updateObjectState } from "../utils/functions"
import { constants } from "../constants"
import { ConditionalView } from "../utils/components"

const color = "#a21b76"

export function AfrimoneyPaymentButton({ onClick }) {
  return (
    <div
      className={"text-center pointer"}
      style={{ backgroundColor: color, borderRadius: 20, padding: 0 }}
      onClick={() => onClick(constants.paymentProcessors.africell)}
    >
      <Image
        src={afrimoney}
        rounded={true}
        height={"60px"}
      />
    </div>
  )
}

export function AfrimoneyPayment({
  data,
  hasPaid,
  confirmPayment,
  setShowModal,
  setModal,
  selectedTicket,
  setData,
  validateForm,
}) {
  return (
    <Card>
      <Card.Header
        className={"py-2"}
        style={{ backgroundColor: color }}
      >
        <h4 className={"text-center text-white"}>Pay with Afrimoney</h4>
      </Card.Header>
      <Card.Body>
        <ConditionalView
          condition={hasPaid}
          falseView={
            <>
              <Card.Title>How It Works</Card.Title>
              <div className={"mt-3"}>
                <ListGroup>
                  <ListGroup.Item>
                    Enter your Afrimoney account number and "Initiate Payment"
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Afrimoney will send you a text to verify and authorize the
                    payment
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Finish the verification and come back here to complete your
                    payment.
                  </ListGroup.Item>
                  <ListGroup.Item>
                    We will text or email you your ticket information after
                    that.
                  </ListGroup.Item>
                </ListGroup>
              </div>
              <br />
              <Form>
                <Form.Label htmlFor="phoneNumber">
                  Afrimoney Phone / Account Number*
                </Form.Label>
                <InputGroup
                  className="mb-3"
                  size={"lg"}
                >
                  <InputGroup.Text id="countryCode">+232</InputGroup.Text>
                  <Form.Control
                    placeholder="e.g. 77123456"
                    aria-label="Afrimoney #"
                    aria-describedby="Phone number input"
                    type={"tel"}
                    id="phoneNumber"
                    value={data.account_number}
                    onChange={(e) => {
                      e.stopPropagation()
                      const val = e.target.value
                      if (val.length > 8) return
                      updateObjectState(setData, { account_number: val })
                    }}
                  />
                </InputGroup>
                <Form.Text>
                  You will receive a text message at this number from Afrimoney
                  with instructions on how to confirm the payment.
                </Form.Text>

                <br />
                <br />
                <div className="d-grid gap-2">
                  <Button
                    className={"text-white"}
                    disabled={!selectedTicket || validateForm()}
                    variant="primary"
                    size={"lg"}
                    style={{ backgroundColor: color }}
                    onClick={(e) => {
                      e.preventDefault()
                      setModal({
                        title: `Confirm Payment of ${
                          selectedTicket.currency
                        } ${formatMoney(
                          selectedTicket.retail_price * parseInt(data.quantity)
                        )}`,
                        body: "Please confirm to proceed. Afrimoney will text you if you have an account with them.",
                        interactive: true,
                      })
                      setShowModal(true)
                    }}
                  >
                    Initiate Payment
                  </Button>
                </div>
              </Form>
            </>
          }
          trueView={
            <>
              <Form>
                <Alert variant="danger">
                  <h2 className={"text-primary text-center"}>
                    Do Not Close This Page
                  </h2>
                  <h5>
                    Complete your payment by following the steps texted to you
                    by Afrimoney. Then click the "Complete Payment" button to
                    receive your ticket information.
                  </h5>
                </Alert>

                <br />
                <br />
                <strong>
                  Afrimoney Trans #: {data.payment_transaction_id}
                </strong>
                <br />
                <br />
                <div className="d-grid gap-2 mb-3">
                  <Button
                    className={"text-white"}
                    variant="primary"
                    size={"lg"}
                    style={{ backgroundColor: color }}
                    onClick={(e) => {
                      e.preventDefault()
                      confirmPayment()
                    }}
                  >
                    Complete Payment
                  </Button>
                </div>
              </Form>
            </>
          }
        />
      </Card.Body>
      <Card.Footer>
        <h4 className={"mb-2"}>Powered By:</h4>
        <Card.Img
          variant="top"
          src={afrimoney}
        />
      </Card.Footer>
    </Card>
  )
}
