import React from "react"
import ReactGA from "react-ga"
import { constants } from "./constants"

export const downLoadVisit = (
  store,
  source,
  newTab = true,
  navigate = true
) => {
  ReactGA.event({
    action: "Download Visit",
    category: store + ":" + source,
    label: store,
    value: 1,
  })
  if (!navigate) return

  window.open(
    store === "ios" ? constants.iosUrl : constants.androidUrl,
    newTab ? "_blank" : "_self"
  )
}
