import React from "react"
import { Row, Col, Card, Button } from "react-bootstrap" // Import Button from React Bootstrap
import ErrorMessage from "../components/ErrorMessage"
import { truncateText } from "../utils/functions"
import { FaShareAltSquare } from "react-icons/fa"
import ShareButtonModal from "../components/ShareButtonModal"

function EventCard({ event, openLink }) {
  const truncatedName = truncateText(event.name, 22)
  const truncatedDescription = truncateText(event.description, 30)
  const truncatedLocation = truncateText(event.full_address, 25)

  const [modalShow, setModalShow] = React.useState(false)

  const eventDetailsHandler = () => {
    openLink(`/event/${event.code}`)
  }

  const shareHandler = (event) => {
    event.preventDefault()
    setModalShow(true)
  }

  return (
    <Col
      sm={6}
      md={3}
      lg={3}
      xl={3}
      className="mb-3 text-justify-center"
    >
      <div className="event-card">
        <Card style={{ height: "40%", overflow: "hidden" }}>
          <div style={{ overflow: "hidden" }}>
            <Card.Img
              variant="top"
              src={event.image}
              alt={event.name}
              style={{
                objectFit: "cover",
                maxHeight: "200px",
                width: "100%",
                height: "100%",
              }}
              className="w-100"
            />
          </div>

          <div className="text-center bg-white mt-6">
            {/* Use the Button component from React Bootstrap */}
            <Button
              variant="light" // Set the button to use a light (white) background
              className="rounded-circle p-3" // Make the button circular and set padding
              onClick={shareHandler}
            >
              <FaShareAltSquare
                color="#d43790"
                size={26}
              />
            </Button>
            <ShareButtonModal
              show={modalShow}
              eventLink={`https://getventix.com/event/${event.code}`}
              onHide={() => setModalShow(false)}
            />
          </div>

          <Card.Body
            variant="bottom"
            onClick={eventDetailsHandler}
          >
            <Card.Title className="text-center font-weight-bold">
              {truncatedName?.toUpperCase()}
            </Card.Title>
            <Card.Text className="text-center text-xs text-muted">
              {truncatedDescription}
            </Card.Text>
            <Card.Text className="text-left text-xs text-muted mb-0">
              <strong className="text-red primary">Date:</strong>{" "}
              {event.start_datetime}
            </Card.Text>
            <Card.Text className="text-left text-xs text-muted">
              <strong>Location:</strong> {truncatedLocation}
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    </Col>
  )
}

function EventList({ events, title, openLink }) {
  if (!events || events.length === 0) {
    return <ErrorMessage content={title} />
  }

  return (
    <Row className="justify-content w-100">
      {events.map((event, index) => (
        <EventCard
          event={event}
          key={index}
          openLink={openLink}
        />
      ))}
    </Row>
  )
}

export default EventList
