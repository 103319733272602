import React, { useEffect, useState } from "react"
import { Container, Row, Col, Card, Button, Image } from "react-bootstrap"
import {
  FaDownload,
  FaUserPlus,
  FaMoneyBill,
  FaQuestionCircle,
  FaTrophy,
  FaBell,
} from "react-icons/fa"
import NavBar from "./NavBar"
import appStore from "../assets/images/app-store.png"
import playStore from "../assets/images/play-store.png"
import { downLoadVisit } from "../analytics"
import ReactGA from "react-ga"

// ReactGA.initialize("UA-161757367-7")

function TriviaInfo() {
  useEffect(() => {
    updateAnalytics()
  }, [])

  const updateAnalytics = (vals) => {
    const urlParams = new URLSearchParams(window.location.search)
    const ref = urlParams.get("ref")

    ReactGA.event({
      action: "Trivia Page Visit",
      category: ref,
      label: ref + " visitor",
      value: 1,
    })
  }

  return (
    <Col className={"bg-secondary bg-opacity-25"}>
      <NavBar />
      <Container className={""}>
        <Row>
          <Col
            md={{ span: 7, offset: 3 }}
            className="text-center"
          >
            <Row>
              <Col className={"mt-4 py-4 bg-primary text-white"}>
                <h1>How Ventix Trivia Works</h1>
                <p>
                  Join us for Trivia Night every day from 7:30pm to 8pm for your
                  chance to win big! Here's how it works:
                </p>
                <h1>Win Up to NLe 250 Everyday!</h1>
              </Col>
            </Row>
            <Row className={"my-3"}>
              <Card className={"border-1 border-primary"}>
                <Card.Body>
                  <Col className="d-flex align-items-center justify-content-center">
                    <div className="icon-bkg bg-primary d-flex justify-content-center align-items-center rounded-circle">
                      <FaDownload
                        size={50}
                        className="text-white"
                      />
                    </div>
                  </Col>
                  <Col className="mt-3">
                    <h3>Step 1: Download the Ventix App</h3>
                    <p>
                      Tap a button below to download the Ventix app for free on
                      your Android or iOS device to participate in Trivia Night.
                    </p>
                  </Col>
                  <Col>
                    <div className={"row pt-4"}>
                      <p>
                        <Image
                          className={"pointer"}
                          fluid={false}
                          src={playStore}
                          height={"auto"}
                          width={"150px"}
                          onClick={() => downLoadVisit("android", "Trivia")}
                        />
                        <span className={"mx-2"}></span>
                        <Image
                          className={"pointer"}
                          fluid={false}
                          src={appStore}
                          height={"auto"}
                          width={"150px"}
                          onClick={() => downLoadVisit("ios", "Trivia")}
                        />
                      </p>
                    </div>
                  </Col>
                </Card.Body>
              </Card>
            </Row>
            <Row className={"my-3"}>
              <Card className={"border-1 border-primary"}>
                <Card.Body>
                  <Col className="d-flex align-items-center justify-content-center">
                    <div className="icon-bkg bg-primary d-flex justify-content-center align-items-center rounded-circle">
                      <FaUserPlus
                        size={32}
                        className="text-white"
                      />
                    </div>
                  </Col>
                  <Col className="mt-3">
                    <h3>Step 2: Create an Account</h3>
                    <p>
                      Create an account in the Ventix app to participate in
                      Trivia Night. It's quick and easy to do, and you only have
                      to do it once.
                    </p>
                  </Col>
                </Card.Body>
              </Card>
            </Row>
            {/*<Row className={"my-3"}>*/}
            {/*  <Card className={"border-1 border-primary"}>*/}
            {/*    <Card.Body>*/}
            {/*      <Col className="d-flex align-items-center justify-content-center">*/}
            {/*        <div className="icon-bkg bg-primary d-flex justify-content-center align-items-center rounded-circle">*/}
            {/*          <FaMoneyBill*/}
            {/*            size={32}*/}
            {/*            className="text-white"*/}
            {/*          />*/}
            {/*        </div>*/}
            {/*      </Col>*/}
            {/*      <Col className="mt-3">*/}
            {/*        <h3>Step 3: Pay the Participation Fee</h3>*/}
            {/*        <p>*/}
            {/*          Pay the participation fee of NLe 2.00 per day to play*/}
            {/*          Trivia Night. This helps us keep the game running and*/}
            {/*          offer exciting prizes to our players.*/}
            {/*        </p>*/}
            {/*        <Button*/}
            {/*          variant="primary"*/}
            {/*          href="#"*/}
            {/*        >*/}
            {/*          Pay Now*/}
            {/*        </Button>*/}
            {/*      </Col>*/}
            {/*    </Card.Body>*/}
            {/*  </Card>*/}
            {/*</Row>*/}
            <Row className={"my-3"}>
              <Card className={"border-1 border-primary"}>
                <Card.Body>
                  <Col className="d-flex align-items-center justify-content-center">
                    <div className="icon-bkg bg-primary d-flex justify-content-center align-items-center rounded-circle">
                      <FaBell
                        size={32}
                        className="text-white"
                      />
                    </div>
                  </Col>
                  <Col className="mt-3">
                    <h3>Step 3: Wait for a Notification fron us</h3>
                    <p>
                      We will send you a push notification 1 hour before the
                      game starts with the topic of the day. We will also notify
                      you when the game starts.
                    </p>
                  </Col>
                </Card.Body>
              </Card>
            </Row>
            <Row className={"my-3"}>
              <Card className={"border-1 border-primary"}>
                <Card.Body>
                  <Col className="d-flex align-items-center justify-content-center">
                    <div className="icon-bkg bg-primary d-flex justify-content-center align-items-center rounded-circle">
                      <FaTrophy
                        size={32}
                        className="text-white"
                      />
                    </div>
                  </Col>
                  <Col className="mt-3">
                    <h3>Step 4: Answer and Win</h3>
                    <p>
                      For each question, we will randomly select one or more
                      people who will win NLe 25 or more. There's a total of 10
                      winners each day, with a prize pool of NLe 250 up for
                      grabs. And the best part? There's no limit to how many
                      questions you can win. If you're lucky, you could walk
                      away with all the prize money, up to NLe 250 per day.
                    </p>
                  </Col>
                </Card.Body>
              </Card>
            </Row>
            <Row className={"my-3"}>
              <Card className={"border-1 border-primary"}>
                <Card.Body>
                  <Col className="d-flex align-items-center justify-content-center">
                    <div className="icon-bkg bg-primary d-flex justify-content-center align-items-center rounded-circle">
                      <FaMoneyBill
                        size={32}
                        className="text-white"
                      />
                    </div>
                  </Col>
                  <Col className="mt-3">
                    <h3>Step 5: Get Your Money!</h3>
                    <p>
                      If you win for one or multiple questions. We will send
                      your money to your Afrimoney / Orange Money number within
                      24 hours after the gane ends. You will have an option to
                      enter your mobile money number in the app.
                    </p>
                  </Col>
                </Card.Body>
              </Card>
            </Row>
            <Row className={"my-3"}>
              <Card className={"border-1 border-primary"}>
                <Card.Header>
                  <Card.Title>App Tutorial</Card.Title>
                  <Card.Subtitle>
                    Here is a video turorial on how the whole process works.
                  </Card.Subtitle>
                </Card.Header>
                <Card.Body>
                  <iframe
                    title={"Buy with Orange Money"}
                    src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFblMNPwt8&#x2F;watch?embed"
                    width={"100%"}
                    height={"350"}
                    loading={"lazy"}
                    allowFullScreen={true}
                    allow={"fullscreen"}
                  ></iframe>
                </Card.Body>
              </Card>
            </Row>
            {/*<Row className={"my-3"}>*/}
            {/*  <Card className={"border-1 border-primary"}>*/}
            {/*    <Card.Header>*/}
            {/*      <Card.Title>Get the Ventix App Now</Card.Title>*/}
            {/*    </Card.Header>*/}
            {/*    <Card.Body>*/}
            {/*      <div className={"row pt-4"}>*/}
            {/*        <p>*/}
            {/*          <Image*/}
            {/*            className={"pointer"}*/}
            {/*            fluid={false}*/}
            {/*            src={playStore}*/}
            {/*            height={"auto"}*/}
            {/*            width={"150px"}*/}
            {/*            onClick={() => downLoadVisit("android", "Trivia")}*/}
            {/*          />*/}
            {/*          <span className={"mx-2"}></span>*/}
            {/*          <Image*/}
            {/*            className={"pointer"}*/}
            {/*            fluid={false}*/}
            {/*            src={appStore}*/}
            {/*            height={"auto"}*/}
            {/*            width={"150px"}*/}
            {/*            onClick={() => downLoadVisit("ios", "Trivia")}*/}
            {/*          />*/}
            {/*        </p>*/}
            {/*      </div>*/}
            {/*    </Card.Body>*/}
            {/*  </Card>*/}
            {/*</Row>*/}
          </Col>
        </Row>
      </Container>
    </Col>
  )
}
export default TriviaInfo
