import { Col, Container, Row, Tab, Tabs } from "react-bootstrap"
import {
  BsCloudDownloadFill,
  BsFileLockFill,
  BsFillCreditCardFill,
} from "react-icons/bs"
import {
  MdAccountCircle,
  MdSpeed,
  MdSendToMobile,
  MdOutlinePhonelinkLock,
  MdDoNotTouch,
} from "react-icons/md"
import { RxEnter } from "react-icons/rx"
import { TbWorldCheck } from "react-icons/tb"
import { PiContactlessPaymentFill } from "react-icons/pi"
import { HiShoppingCart } from "react-icons/hi"
import { FaRegThumbsUp, FaHandHoldingUsd } from "react-icons/fa"
import CashAccountBenifit from "./CashAccountBenifit"
import CashAccount from "./CashAccount"
import ContactlessCard from "./ContactlessCard"
import VirtualCard from "./VirtualCard"
import { Fade } from "react-awesome-reveal"

const CashAccountInfo = [
  {
    icon: BsCloudDownloadFill,
    title: "Download App",
    text: "Download the app from App Store or Google Play Store",
  },
  {
    icon: MdAccountCircle,
    title: "Create Account",
    text: "Sign Up using your phone number or email address",
  },
  {
    icon: MdSendToMobile,
    title: "Send",
    text: `Enter the amount, choose a recipient (phone number, or Ventix Username), and tap "send." It's free!`,
  },
  {
    icon: RxEnter,
    title: "Receive",
    text: "Share your Ventix Username or share your QR code. Deposits usually arrive instantly",
  },
]
const ContactlessCardInfo = [
  {
    icon: FaRegThumbsUp,
    title: "Intuitive",
    text: "No need to fumble for your card or enter a PIN for smaller transactions, making it ideal for everyday purchases",
  },
  {
    icon: BsFileLockFill,
    title: "Secure",
    text: "Our Contactless cards has strong security of PIN protection (generating unique codes for transactions instead of your actual card number), and fraud monitoring.",
  },
  {
    icon: PiContactlessPaymentFill,
    title: "Payment",
    text: "Make a payment by just tapping the card to your Phone.",
  },
  {
    icon: MdSpeed,
    title: "Fast",
    text: "Transactions are much faster than swiping or inserting your card, reducing checkout times and queues",
  },
]

const virtualCard = [
  {
    icon: HiShoppingCart,
    title: "Online Payment",
    text: "Use the card for online purchases anywhere that accepts Mastercard or Visa (depending on the card network) ",
  },
  {
    icon: BsFillCreditCardFill,
    title: "Fund your Card",
    text: "Add funds to the card from your  Ventix Cash Account, or Bank account.",
  },
  {
    icon: MdOutlinePhonelinkLock,
    title: "Secure your Card",
    text: "Create a Pin and fully control your card with your phone.",
  },
  {
    icon: FaHandHoldingUsd,
    title: "International Transaction",
    text: "Use the card internationally with lower fees compared to traditional credit cards.",
  },
  {
    icon: MdDoNotTouch,
    title: "No Physical Card Required",
    text: "There's no need to wait for a physical card to arrive. our Virtual Card is instantly available for use.",
  },
  {
    icon: TbWorldCheck,
    title: "Global Acceptance",
    text: "Our virtual cards are accepted by merchants worldwide, wherever Visa or Mastercard is supported.",
  },
]

export default function HowItWorks() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  return (
    <>
      <Tabs
        defaultActiveKey="cashAccount"
        transition={true}
        className={"mb-3 fixed-top py-3 px-5 bg-white justify-content-center"}
        style={{ position: "sticky" }}
        onClick={scrollToTop}
      >
        <Tab
          tabClassName={"text-primary"}
          eventKey="cashAccount"
          title="Cash Account"
          className={"p-5 centered fixed top "}
        >
          <h2 className={"text-center justify-content-center mb-5"}>
            How It Works
          </h2>
          <Fade cascade>
            <Row className="my-5 px-5">
              {CashAccountInfo.map((item, i) => (
                <Col
                  lg={"3"}
                  md={"6"}
                  key={i}
                  className={"text-center px-5 justify-content-center mb-5"}
                >
                  <item.icon className={"text-white fs-1 icon-bkg"} />
                  <h4>{item.title}</h4>
                  <p className="text-center">{item.text}</p>
                </Col>
              ))}
            </Row>
          </Fade>
          <CashAccountBenifit />

          <CashAccount />
        </Tab>
        <Tab
          tabClassName={"text-primary"}
          eventKey="contactlessCard"
          title="Contactless Card"
          className={"py-5  centered "}
        >
          <h2 className={"text-center justify-content-center mb-5"}>
            The Contactless Card Experience
          </h2>
          <Fade cascade>
            <Row className="my-5 px-5">
              {ContactlessCardInfo.map((item, i) => (
                <Col
                  lg={"3"}
                  md={"6"}
                  key={i}
                  className={"text-center px-5justify-content-center mb-5"}
                >
                  <item.icon className={"text-white fs-1 icon-bkg"} />
                  <h4>{item.title}</h4>
                  <p className="text-center">{item.text}</p>
                </Col>
              ))}
            </Row>
          </Fade>
          <ContactlessCard />
        </Tab>
        <Tab
          tabClassName={"text-primary"}
          eventKey="virtualCard"
          title="Virtual USD Card"
          className={"py-5 centered"}
        >
          <h2 className={"text-center justify-content-center mb-5"}>
            The Virtual USD Card Benefits
          </h2>
          <Fade cascade>
            <Row className="my-5 px-5">
              {virtualCard.map((item, i) => (
                <Col
                  lg={"4"}
                  md={"6"}
                  key={i}
                  className={"text-center px-5  mb-5"}
                >
                  <item.icon className={"text-white fs-1 icon-bkg"} />
                  <h4>{item.title}</h4>
                  <p className="text-center">{item.text}</p>
                </Col>
              ))}
            </Row>
          </Fade>
          <VirtualCard />
        </Tab>
      </Tabs>
    </>
  )
}
