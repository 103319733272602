import { Button, Card, Image, ListGroup } from "react-bootstrap"
import logo from "../assets/images/orange-money-logo.png"
import { constants } from "../constants"
import { FaPhone, FaCopy } from "react-icons/fa"
import { useState } from "react"

const color = "#000000"

export function OrangeMoneyPaymentButton({ onClick }) {
  return (
    <div
      className={"text-center pointer"}
      style={{ backgroundColor: color, borderRadius: 20, padding: 0 }}
      onClick={() => onClick(constants.paymentProcessors.orange)}
    >
      <Image
        src={logo}
        rounded={true}
        height={"60px"}
      />
    </div>
  )
}

export function OrangeMoneyPayment({
  data,
  selectedTicket,
  validateForm,
  submitPayment,
}) {
  const phone = "#144*3*12#"

  const userAgent = navigator.userAgent || navigator.vendor || window.opera
  const [isPhone, setIsPhone] = useState(
    /iPhone|iPad|iPod|Android/i.test(userAgent)
  )
  const [copySuccess, setCopySuccess] = useState(false)
  const isAndroid = /Android/i.test(userAgent)

  const handleCall = () => {
    const encodedPhone = encodeURIComponent(phone)

    if (isPhone && isAndroid) {
      // navigator.clipboard.writeText(phone)
      window.location.href = `tel:${encodedPhone}`
    } else {
      window.location.href = `tel:${phone}`
    }
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(phone)
    setCopySuccess(true)
    setTimeout(() => setCopySuccess(false), 3000)
  }

  return (
    <Card className={""}>
      <Card.Header className={"bg-dark py-2"}>
        <h4 className={"text-center text-white"}>Pay with Orange Money</h4>
      </Card.Header>
      <Card.Body>
        <Card.Title>How It Works</Card.Title>
        <Card.Subtitle>
          We will direct you to Orange Money's payment portal.
          <br />
          They wiil ask for 2 things
        </Card.Subtitle>
        <div className={"mt-3"} />

        <Card body={true}>
          <Card.Title>1. Your Orange Money Phone #</Card.Title>
          <Card.Text>Enter your number without the 0 e.g. 76123456</Card.Text>
        </Card>
        <Card body={true}>
          <Card.Title>2. Payment Code</Card.Title>
          <Card.Text>
            Dial the short code #144*3*12# to get the 6 digit code you will
            enter in the "Payment Code" field.
          </Card.Text>
          <div className="d-flex flex-column align-items-center">
            {isPhone ? (
              <Button
                variant="primary"
                size="lg"
                onClick={handleCall}
                className={"text-white"}
              >
                <FaPhone
                  size={24}
                  className="mr-2"
                />{" "}
                Dial {phone}
              </Button>
            ) : (
              <Button
                variant="secondary"
                size="lg"
                onClick={handleCopy}
              >
                <FaCopy
                  size={24}
                  className="mr-2"
                />{" "}
                Copy {phone}
              </Button>
            )}
            <div className="text-muted mt-2">
              {isPhone
                ? "Tap to dial the short code."
                : "Tap to copy the number."}
            </div>
            {copySuccess && (
              <div className="position-absolute top-100 start-50 translate-middle-x">
                <div
                  className="alert alert-success text-center"
                  role="alert"
                >
                  Phone number copied to clipboard
                </div>
              </div>
            )}
          </div>
        </Card>

        <div className="d-grid gap-2 my-3">
          <Button
            className={"text-white"}
            disabled={!selectedTicket || validateForm()}
            variant="primary"
            size={"lg"}
            style={{ backgroundColor: color }}
            onClick={(e) => {
              e.preventDefault()
              submitPayment()
            }}
          >
            Proceed to Orange Money
          </Button>
        </div>
      </Card.Body>
      <Card.Footer>
        <h4 className={"mb-2"}>Powered By:</h4>
        <Card.Img
          variant="top"
          src={logo}
        />
      </Card.Footer>
    </Card>
  )
}
