export const constants = {
  appName: "Ventix",
  contacts: "www.getventix.com | 0(80/73) 778877",
  iosUrl: "https://apps.apple.com/us/app/ventix/id1600378823",
  androidUrl: "https://play.google.com/store/apps/details?id=com.getventix.api",
  linksUrl: "https://ventix.biz/links",
  unknownErrorMsg:
    "An unknown error occurred. Please try again later or contact us if you keep seeing this message.",
  paymentProcessors: {
    africell: "Africell",
    stripe: "Stripe",
    orange: "Orange",
    ventix: "Ventix",
  },
  authTokenKey: "authToken",
}

export const sectionSpace = "mb-5"
