import React, { useState, useEffect } from "react"
import { Container, Form, Button, Row, Col, Card } from "react-bootstrap"
import { useLocation } from "react-router-dom"
import NavBar from "../PageComponents/NavBar"

function UnsubscribePage() {
  const location = useLocation()

  const [formData, setFormData] = useState({
    email: "",
    event_email_notifications: true,
    trivia_email_notifications: true,
  })

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const decodedEmail = decodeURIComponent(params.get("email") || "")
    setFormData((prevData) => ({ ...prevData, email: decodedEmail }))
  }, [location])

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target
    setFormData((prevData) => ({ ...prevData, [name]: checked }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    // Handle the form submission logic here
    console.log(formData)
  }

  return (
    <div className={"bg-secondary bg-opacity-25 min-vh-100"}>
      <NavBar />
      <Container>
        <Card className={"mt-4"}>
          <Card.Header className={"px-3 py-4"}>
            <Card.Title>Unsubscribe from Email Notifications</Card.Title>
          </Card.Header>
          <Card.Body className={"py-5 px-3"}>
            <Form onSubmit={handleSubmit}>
              <Form.Group
                as={Row}
                className={"mb-4"}
              >
                <Form.Label
                  column
                  sm="2"
                >
                  Email:
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    type="email"
                    value={formData.email}
                    readOnly
                    disabled={true}
                  />
                </Col>
              </Form.Group>

              <Form.Group className="mb-5">
                <Form.Check
                  type="checkbox"
                  name="event_email_notifications"
                  label="Receive Event Email Notifications"
                  checked={formData.event_email_notifications}
                  onChange={handleCheckboxChange}
                  className={"mb-3"}
                />
                <Form.Check
                  type="checkbox"
                  name="trivia_email_notifications"
                  label="Receive Trivia Email Notifications"
                  checked={formData.trivia_email_notifications}
                  onChange={handleCheckboxChange}
                />
              </Form.Group>

              <Button
                variant="primary"
                type="submit"
              >
                Submit
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </div>
  )
}

export default UnsubscribePage
