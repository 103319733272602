import { Card, Stack, Table, Container } from "react-bootstrap"

import React, { useState, useEffect, useMemo, useRef } from "react"
import { Modal, Button } from "react-bootstrap"

import { constants } from "../constants"
import { AfrimoneyPayment, AfrimoneyPaymentButton } from "./AfrimoneyPayment"
import {
  OrangeMoneyPayment,
  OrangeMoneyPaymentButton,
} from "./OrangeMoneyPayment"
import { formatMoney } from "../utils/functions"
import { StripePayment, StripePaymentButton } from "./StripePayment"

const payment = (
  amount,
  processor,
  paymentProvidersConfigs,
  selectedTicket
) => {
  const percentage =
    paymentProvidersConfigs[selectedTicket.currency][processor]
      .processing_fee_percentage
  const added_amount =
    paymentProvidersConfigs[selectedTicket.currency][processor].processing_fee
  let fee = amount * percentage + added_amount
  if (
    paymentProvidersConfigs[selectedTicket.currency][processor].round_up_fees
  ) {
    fee += 1 - (fee % 1)
  }
  const total = amount + fee
  fee = total - amount
  return {
    amount,
    fee,
    total,
  }
}

function PaymentSummary({
  processor,
  selectedTicket,
  data,
  paymentProvidersConfigs,
}) {
  const paymentInfo = payment(
    selectedTicket.retail_price * parseInt(data.quantity),
    processor,
    paymentProvidersConfigs,
    selectedTicket
  )

  return (
    <Container>
      <h4 className={"text-center my-3"}>{processor}</h4>
      <Table responsive={true}>
        <tbody>
          <tr>
            <td>Ticket (({data.quantity}))</td>
            <td className={"text-end"}>
              {selectedTicket.currency} {formatMoney(paymentInfo.amount)}
            </td>
          </tr>
          <tr>
            <td>Processing Fee</td>
            <td className={"text-end"}>
              {selectedTicket.currency} {formatMoney(paymentInfo.fee)}
            </td>
          </tr>
          <tr>
            <td className={""}>
              <strong>Total</strong>
            </td>
            <td className={"text-end"}>
              {selectedTicket.currency} {formatMoney(paymentInfo.total)}
            </td>
          </tr>
        </tbody>
      </Table>
      <p className={"text-center mt-4"}>Click below to pay with</p>
    </Container>
  )
}

export function PaymentOptions({
  selectedTicket,
  data,
  paymentProvidersConfigs,
  onClick,
}) {
  return (
    <Card className={"mt-3"}>
      <Card.Header className={"text-center mt-4"}>
        <Card.Title>Order Summary</Card.Title>
        <Card.Subtitle>
          Different payment providers charge different fees for processing your
          payment.
        </Card.Subtitle>
      </Card.Header>

      <Card.Body>
        {selectedTicket &&
          selectedTicket.payment_processors.map((processor, i) => {
            return (
              <Stack
                key={i}
                gap={3}
              >
                <PaymentSummary
                  processor={processor}
                  selectedTicket={selectedTicket}
                  data={data}
                  paymentProvidersConfigs={paymentProvidersConfigs}
                />

                {processor === constants.paymentProcessors.africell && (
                  <div className={"mb-4"}>
                    <AfrimoneyPaymentButton onClick={onClick} />
                  </div>
                )}
                {processor === constants.paymentProcessors.orange && (
                  <div>
                    <OrangeMoneyPaymentButton onClick={onClick} />
                  </div>
                )}
                {processor === constants.paymentProcessors.stripe && (
                  <div>
                    <StripePaymentButton onClick={onClick} />
                  </div>
                )}
              </Stack>
            )
          })}
      </Card.Body>
    </Card>
  )
}

/* Original */
// export function PaymentProviders({
//   data,
//   hasPaid,
//   confirmPayment,
//   setShowModal,
//   setModal,
//   selectedTicket,
//   setData,
//   validateForm,
//   paymentProviderRef,
//   submitPayment,
//   event,
//   event,
// }) {

//   return (
//     <div
//       className={"mt-3"}
//       ref={paymentProviderRef}
//     >
//       {data.processor === constants.paymentProcessors.africell && (
//         <div>
//           <AfrimoneyPayment
//             data={data}
//             hasPaid={hasPaid}
//             confirmPayment={confirmPayment}
//             setShowModal={setShowModal}
//             setModal={setModal}
//             selectedTicket={selectedTicket}
//             setData={setData}
//             validateForm={validateForm}
//           />
//         </div>
//       )}
//       {data.processor === constants.paymentProcessors.orange && (
//         <div>
//           <OrangeMoneyPayment
//             data={data}
//             selectedTicket={selectedTicket}
//             validateForm={validateForm}
//             submitPayment={submitPayment}
//           />
//         </div>
//       )}
//       {data.processor === constants.paymentProcessors.stripe && (
//         <div>
//           <StripePayment
//             data={data}
//             selectedTicket={selectedTicket}
//             validateForm={validateForm}
//             submitPayment={submitPayment}
//           />
//         </div>
//       )}
//     </div>
//   )
// }
/* Original Ends*/

export function PaymentProviders({
  data,
  hasPaid,
  confirmPayment,
  setShowModal,
  selectedTicket,
  setData,
  validateForm,
  paymentProviderRef,
  submitPayment,
  setModal,
  event,
  setIsLoading,
}) {
  const renderPaymentComponent = () => {
    switch (data?.processor) {
      case constants.paymentProcessors.africell:
        return (
          <AfrimoneyPayment
            data={data}
            hasPaid={hasPaid}
            confirmPayment={confirmPayment}
            setShowModal={setShowModal}
            setModal={setModal}
            selectedTicket={selectedTicket}
            setData={setData}
            validateForm={validateForm}
          />
        )
      case constants.paymentProcessors.orange:
        return (
          <OrangeMoneyPayment
            data={data}
            selectedTicket={selectedTicket}
            validateForm={validateForm}
            submitPayment={submitPayment}
          />
        )
      case constants.paymentProcessors.stripe:
        return (
          <StripePayment
            data={data}
            selectedTicket={selectedTicket}
            validateForm={validateForm}
            submitPayment={submitPayment}
          />
        )
      default:
        return null
    }
  }

  return (
    <div
      className="mt-3"
      ref={paymentProviderRef}
    >
      {data?.processor && (
        <Modal
          show={true}
          onHide={() => setData({ ...data, processor: null })}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>{renderPaymentComponent()}</Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => setData({ ...data, processor: null })}
              className="text-white"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  )
}
