import React, { useState } from "react"
import { Form, Button } from "react-bootstrap"
import { WRISTBAND_DIMENSIONS } from "../components/tickets/wristbandticket"

const WristbandForm = ({ initialState, onSubmit }) => {
  const [formData, setFormData] = useState(initialState)

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    onSubmit(formData)
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-4">
        <Form.Label>Number of wristbands to print simultaneously</Form.Label>
        <Form.Control
          type="number"
          name="simultaneously"
          value={formData.simultaneously || ""}
          onChange={handleChange}
        />
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Label>Number of wristbands per roll</Form.Label>
        <Form.Control
          type="number"
          name="perRoll"
          value={formData.perRoll}
          onChange={handleChange}
        />
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Label>Select Dimension</Form.Label>
        <Form.Control
          as="select"
          name="dimensions"
          value={formData.dimensions || ""}
          onChange={handleChange}
        >
          <option value="">-- Select a Dimension --</option>
          {Object.keys(WRISTBAND_DIMENSIONS).map((key) => (
            <option
              key={key}
              value={key}
            >
              {WRISTBAND_DIMENSIONS[key].desc}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      <Button
        variant="primary"
        type="submit"
      >
        Submit
      </Button>
    </Form>
  )
}

export default WristbandForm
