import React, { useEffect, useRef } from "react"

function InfiniteScroll({ onLoadMore, hasMore }) {
  // Create a reference to an element that will be observed for intersection
  const observerRef = useRef()

  useEffect(() => {
    // Define options for the IntersectionObserver, specifying when to trigger the callback
    const options = {
      root: null, // The viewport is used as the root
      rootMargin: "0px", // No margin around the root
      threshold: 0.1, // Trigger when 10% of the element is visible
    }

    // Create an IntersectionObserver instance and start observing the reference element
    const observer = new IntersectionObserver(handleIntersect, options)
    observer.observe(observerRef.current)

    // Clean up by disconnecting the observer when the component unmounts
    return () => {
      observer.disconnect()
    }
  }, [hasMore])

  // Callback function for intersection changes
  const handleIntersect = (entries) => {
    // Check if the observed element is intersecting the viewport and there are more items to load
    if (entries[0].isIntersecting && hasMore) {
      // Call the onLoadMore callback when the element is intersecting
      onLoadMore()
    }
  }

  // Return a JSX element with a reference to the observed element
  return <div ref={observerRef} />
}

export default InfiniteScroll
