import { Button, Card, Image, ListGroup } from "react-bootstrap"
import logo from "../assets/images/cardsLogo.jpg"
import logoBlue from "../assets/images/stripeLogoBlue.png"
import { constants } from "../constants"

const color = "#008cdd"

export function StripePaymentButton({ onClick }) {
  return (
    <div
      className={"text-center pointer"}
      style={{
        backgroundColor: color,
        borderRadius: 20,
        paddingTop: 15,
        paddingBottom: 15,
      }}
      onClick={() => onClick(constants.paymentProcessors.stripe)}
    >
      <Image
        src={logo}
        rounded={true}
        height={"40px"}
        // style={{marginT}}
      />
    </div>
  )
}

export function StripePayment({
  data,
  selectedTicket,
  validateForm,
  submitPayment,
}) {
  return (
    <Card className={""}>
      <Card.Header
        className={"py-2"}
        style={{ backgroundColor: color }}
      >
        <h4 className={"text-center text-white"}>
          Pay with Credit / Debit Card
        </h4>
      </Card.Header>
      <Card.Body>
        <Card.Title>How It Works</Card.Title>
        <Card.Subtitle>
          You will be redirected to our secure checkout page, where you will
          complete the payment.
        </Card.Subtitle>
        <div className={"mt-5"} />

        <div className="d-grid gap-2 my-4">
          <Button
            className={"text-white"}
            disabled={!selectedTicket || validateForm()}
            variant="primary"
            size={"lg"}
            style={{ backgroundColor: color }}
            onClick={(e) => {
              e.preventDefault()
              submitPayment()
            }}
          >
            Proceed to Checkout
          </Button>
        </div>
      </Card.Body>
      <Card.Footer>
        <h4 className={"mb-2"}>Powered By:</h4>
        <Card.Img
          variant="top"
          src={logoBlue}
        />
      </Card.Footer>
    </Card>
  )
}
