import { Alert, Card } from "react-bootstrap"
import { DisplayText } from "../utils/components"

export function EventDetails({ event }) {
  return (
    <Card>
      <Card.Img
        variant="top"
        src={event.image}
      />
      <Card.Body>
        <Alert variant={"success"}>
          <h5>Event Code: {event.code}</h5>
          <p className={"text-muted"}>
            Ensure that this event code matches the code advertised by the event
            organizer, before purchasing a ticket
          </p>
        </Alert>
        <Card.Title className={"text-primary"}>{event.datetime}</Card.Title>
        <div className={"mt-3"}>
          <h4>{event.name}</h4>
          <h5 className={"text-muted mt-1"}>{event.organizer.name}</h5>
          <h6 className={"mt-3 mb-2"}>
            <DisplayText text={event.description} />
          </h6>
          <br />
          <strong>Address:</strong>
          <h6>{event.full_address}</h6>
        </div>
      </Card.Body>
    </Card>
  )
}
