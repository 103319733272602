import { Col, Container, Row, Tab, Tabs } from "react-bootstrap"
import { BsCloudDownloadFill, BsCashStack } from "react-icons/bs"
import {
  MdAccountCircle,
  MdOutlineQrCode2,
  MdQrCodeScanner,
} from "react-icons/md"
import { ImTicket } from "react-icons/im"

const Attendees = [
  {
    icon: BsCloudDownloadFill,
    title: "Download App",
    text: "Download the app from your preferred App Store",
  },
  {
    icon: MdAccountCircle,
    title: "Create Account",
    text: "Sign Up using your phone number or email address",
  },
  {
    icon: ImTicket,
    title: "Purchase Ticket",
    text: "View events in your area and purchase tickets using mobile money or credit card",
  },
  {
    icon: MdOutlineQrCode2,
    title: "Get Access",
    text: "Show your ticket's QR code or number at the event entrance for verification",
  },
]

const Organizers = [
  {
    icon: BsCloudDownloadFill,
    title: "Download App",
    text: "Download the app from your preferred App Store",
  },
  {
    icon: MdAccountCircle,
    title: "Create Account",
    text: "Sign Up using your phone number or email address",
  },
  {
    icon: BsCashStack,
    title: "Sell Tickets",
    text: "Create events and sell multiple ticket types. You have full control.",
  },
  {
    icon: MdQrCodeScanner,
    title: "Verify Tickets",
    text: "Verify attendees tickets by scanning their ticket's QR code or entering their ticket number",
  },
]

export default function HowItWorks() {
  return (
    <Container
      className={"py-5"}
      id={"hiw"}
    >
      <h1 className={"text-center mb-5"}>How It Works</h1>
      <Tabs
        defaultActiveKey="attendees"
        className={"mb-3 justify-content-center"}
      >
        <Tab
          tabClassName={"text-primary"}
          eventKey="attendees"
          title="Attendees"
          className={"py-5"}
        >
          <Row>
            {Attendees.map((item, i) => (
              <Col
                md={"3"}
                xs={"6"}
                key={i}
                className={"text-center mb-5"}
              >
                <item.icon className={"text-white fs-1 icon-bkg"} />
                <h4>{item.title}</h4>
                <p>{item.text}</p>
              </Col>
            ))}
          </Row>
        </Tab>

        <Tab
          tabClassName={"text-primary"}
          eventKey="organizers"
          title="Organizers"
          className={"py-5"}
        >
          <Row>
            {Organizers.map((item, i) => (
              <Col
                md={"3"}
                xs={"6"}
                className={"text-center mb-5"}
              >
                <item.icon className={"text-white fs-1 icon-bkg"} />
                <h4>{item.title}</h4>
                <p>{item.text}</p>
              </Col>
            ))}
          </Row>
        </Tab>
      </Tabs>
    </Container>
  )
}
