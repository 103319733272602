export const updateObjectState = (state, vals) => {
  state((prevState) => ({
    ...prevState,
    ...vals,
  }))
}

export const isEmpty = (item) => {
  return item === "" || item === null || item === undefined
}

export const formatMoney = (val) => {
  if (typeof val !== "string") {
    // val = Math.ceil(val * 100) / 100
    val = val.toFixed(2).toString()
  }
  let amt = val.replace(".", "").replace(/,/g, "")
  const last = amt.length - 1
  const secondLast = amt.length > 1 ? amt.length - 2 : null
  let beforeDecimal = amt.length > 2 ? amt.substr(0, secondLast) : "0"
  beforeDecimal = parseInt(beforeDecimal).toLocaleString("en-US")
  return `${beforeDecimal}.${
    secondLast ? amt.charAt(secondLast) : "0"
  }${amt.charAt(last)}`
}

export const globalReplaceComma = (val, replacer = "") => {
  return val.replace(/,/g, replacer)
}

export const strToFloat = (val) => {
  val = globalReplaceComma(val)
  return parseFloat(val)
}

export function arrangeTickets(tickets, perRoll, simultaneously) {
  // Create an array to hold the rearranged tickets
  let rearrangedTickets = []

  // Calculate the total number of tickets
  const totalTickets = tickets.length

  // Calculate the total number of rounds
  const totalRounds = totalTickets / (perRoll * simultaneously)

  // Loop over each round
  for (let round = 0; round < perRoll; round++) {
    // Create a temporary array to hold the tickets for this round
    let roundTickets = []
    // For each round, loop over the tickets that will be printed simultaneously
    for (let i = 0; i < simultaneously; i++) {
      // Calculate the index for this ticket
      const ticketIndex = i * perRoll + round
      if (ticketIndex > totalTickets - 1) {
        continue
      }
      // Add the ticket to the temporary array
      roundTickets.push(tickets[ticketIndex])
    }
    // Add the round tickets to the rearranged tickets
    rearrangedTickets.push(roundTickets)
  }

  // Return the rearranged tickets
  return rearrangedTickets
}

export function truncateText(text, maxLength) {
  if (text.length <= maxLength) {
    return text
  }
  return text.slice(0, maxLength) + "..."
}

export function mmToPixels(mm) {
  const dpi = 96 // Standard screen resolution
  const inches = mm / 25.4 // Convert mm to inches
  return inches * dpi // Convert inches to pixels
}

export function mmToPdfPoints(mm) {
  // react-pdf uses points instead of pixels to measure
  return (mm / 25.4) * 72
}

export const truncateTextDynamically = (
  text,
  fontSize, // in points
  viewWidth,
  maxLines
) => {
  const averageCharWidth = fontSize * 0.5 // Approximate width of each character
  const maxCharsPerLine = Math.floor(viewWidth / averageCharWidth)

  const maxChars = maxCharsPerLine * maxLines
  let truncatedText = text.slice(0, maxChars)

  if (text.length > maxChars) {
    truncatedText = truncatedText.slice(0, -3) + "..." // Replace last 3 characters with ellipsis
  }

  return truncatedText
}
