import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Modal,
  Row,
} from "react-bootstrap"
import { useEffect, useState, useRef } from "react"
import { apiRequest } from "../requests"
import { useParams } from "react-router-dom"
import NavBar from "./NavBar"
import { LoadingView } from "../utils/components"
import { EventDetails } from "../components/EventDetails"
import { TicketSelection } from "../components/TicketSelection"
import { isEmpty, updateObjectState } from "../utils/functions"
import { PaymentOptions, PaymentProviders } from "../components/PaymentOptions"
import { constants } from "../constants"
import { Helmet } from "react-helmet"
import VotingPeriodListItem from "./components/Event/VotingPeriodListItem"

const initData = {
  account_number: "",
  isEmail: true,
  tier_id: null,
  quantity: 1,
  contact: "",
  processor: "",
  currency: "", // currency variable
  attendee_names: [""],
  is_latest_v: true,
}

export default function Event() {
  useEffect(() => {
    getEvent()
  }, [])

  const validateForm = () => {
    let disable =
      (isEmpty(data.account_number) &&
        data.processor === constants.paymentProcessors.africell) ||
      isEmpty(data.tier_id) ||
      isEmpty(data.contact) ||
      data.attendee_names.includes("")
    return disable
  }

  const paymentProviderRef = useRef(null)

  const [modal, setModal] = useState({})
  const [selectedTicket, setSelectedTicket] = useState({
    quantity: 0,
    retail_price: 0,
    payment_processors: [],
  })
  const [hasPaid, setHasPaid] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState(initData)
  const [event, setEvent] = useState({ organizer: {}, tickets: [] })

  const { eventCode } = useParams()

  const getEvent = async () => {
    setIsLoading(true)
    const urlParams = new URLSearchParams(window.location.search)
    const orderNumber = urlParams.get("orderNumber")

    let endpoint = `api/event/${eventCode}`
    if (orderNumber) {
      endpoint = `api/order/payment/validation?orderNumber=${orderNumber}&eventCode=${eventCode}`
    }
    const { success, response } = await apiRequest.get(endpoint)
    let activeTicket = {}
    if (success) {
      const loadedEvent = response.event
      setEvent(loadedEvent)
      activeTicket = loadedEvent.tickets[0]

      if (orderNumber && response.payment_verified) {
        setModal({
          title: "Payment Successful",
          body: response.message,
        })
        setShowModal(true)
      } else if (orderNumber && response.form_data) {
        const formData = response.form_data
        setData(formData)
        activeTicket = loadedEvent.tickets.filter(
          (ticket) => ticket.id === formData.tier_id
        )[0]
      }
      setSelectedTicket(activeTicket)
      updateObjectState(setData, { tier_id: activeTicket.id })
    } else {
      setModal({
        title: "Error",
        body: response.message,
      })
      setShowModal(true)
    }
    setIsLoading(false)
  }

  const submitPayment = async () => {
    setIsLoading(true)
    setShowModal(false)
    const { success, response } = await apiRequest.post(
      `api/event/${event.id}/pay-init`,
      data
    )

    if (success) {
      if (data.processor === constants.paymentProcessors.africell) {
        setIsLoading(false)
        setHasPaid(true)

        setShowModal(false)
        updateObjectState(setData, {
          payment_transaction_id: response.payment_transaction_id,
        })
        paymentProviderRef.current?.scrollIntoView({
          block: "nearest",
          behavior: "smooth",
        })
      } else {
        window.location.href = response.payment_url
      }
    } else {
      setModal({
        title: "Error",
        body: response.message,
      })
      setShowModal(true)
      setIsLoading(false)
    }
  }

  const confirmPayment = async () => {
    setIsLoading(true)
    setShowModal(false)
    const { success, response } = await apiRequest.post(
      `api/event/pay-verify`,
      data
    )

    if (success) {
      setHasPaid(false)
      setModal({
        title: "Success",
        body: response.message,
      })
      setShowModal(true)
      setData(initData)
    } else {
      setModal({
        title: "Error",
        body: response.message,
      })
      setShowModal(true)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    paymentProviderRef.current?.scrollIntoView({
      block: "nearest",
      behavior: "smooth",
    })
  }, [data.processor])

  return (
    <div className={"bg-secondary bg-opacity-25"}>
      <NavBar />
      <LoadingView
        isLoading={isLoading}
        view={
          <Container className={"mb-5 pb-5"}>
            {event?.id && (
              <Helmet>
                <title>{event.name}</title>
                <meta
                  property="og:title"
                  content={event.name}
                />
                <meta
                  property="og:description"
                  content={event.description}
                />
                <meta
                  property="og:image"
                  content={event.image}
                />
                <meta
                  property="og:url"
                  content={"https://getventix.com/event/" + event.code}
                />
                {/* Other Open Graph tags as needed */}
              </Helmet>
            )}
            <Row>
              <Col
                lg={"6"}
                className={"mt-4"}
              >
                <EventDetails event={event} />
                {event.category === "Voting" && (
                  <div className={"mt-3"}>
                    <Accordion className={"px-0"}>
                      <Card className={"px-0"}>
                        <Accordion.Header
                          eventKey="0"
                          className={"primary-accordion-header"}
                        >
                          <h4 className={"text-white"}>Voting Categories</h4>
                        </Accordion.Header>
                        <Accordion.Body>
                          {event.voting_periods.map((period) => (
                            <div
                              key={period.id}
                              className={"mb-3"}
                            >
                              <VotingPeriodListItem period={period} />
                            </div>
                          ))}
                        </Accordion.Body>
                      </Card>
                    </Accordion>
                  </div>
                )}
              </Col>
              <Col
                lg={"6"}
                className={"mt-4"}
              >
                <TicketSelection
                  event={event}
                  data={data}
                  setData={setData}
                  selectedTicket={selectedTicket}
                  setSelectedTicket={setSelectedTicket}
                />
                <PaymentOptions
                  data={data}
                  selectedTicket={selectedTicket}
                  paymentProvidersConfigs={event.payment_processors_configs}
                  onClick={(val) => {
                    updateObjectState(setData, { processor: val })
                  }}
                />

                <PaymentProviders
                  paymentProviderRef={paymentProviderRef}
                  data={data}
                  hasPaid={hasPaid}
                  confirmPayment={confirmPayment}
                  setShowModal={setShowModal}
                  setModal={setModal}
                  selectedTicket={selectedTicket}
                  setData={setData}
                  validateForm={validateForm}
                  submitPayment={submitPayment}
                  event={event}
                  setIsLoading={setIsLoading}
                />
              </Col>
            </Row>

            <AlertModal
              modal={modal}
              setShowModal={setShowModal}
              showModal={showModal}
              submitPayment={submitPayment}
            />
          </Container>
        }
      />
    </div>
  )
}

function AlertModal({ modal, showModal, setShowModal, submitPayment }) {
  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>{modal.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{modal.body}</Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setShowModal(false)
          }}
        >
          {!modal.interactive ? "Ok" : "No"}
        </Button>
        {modal.interactive && (
          <Button
            variant="primary"
            onClick={() => {
              submitPayment()
            }}
          >
            Yes
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}
