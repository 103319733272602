import React, { useState } from "react"
import { Modal, Form, Row, Col, InputGroup } from "react-bootstrap"
import { FaCopy } from "react-icons/fa"
import { RiTwitterXLine } from "react-icons/ri"
import { BsFacebook, BsMessenger } from "react-icons/bs"

function ShareButtonModal(props) {
  const [isCopied, setIsCopied] = useState(false)
  const [eventLink, setEventLink] = useState(props.eventLink)

  const copyToClipboard = () => {
    const textToCopy = eventLink
    const textArea = document.createElement("textarea")
    textArea.value = textToCopy
    document.body.appendChild(textArea)
    textArea.select()
    document.execCommand("copy")
    document.body.removeChild(textArea)
    setIsCopied(true)
  }

  const shareOnFacebook = () => {
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${eventLink}`
    window.open(shareUrl, "_blank")
  }

  const shareOnMessenger = () => {
    const shareUrl = `https://www.facebook.com/v2.8/dialog/send?app_id=28218816837&channel_url=${encodeURIComponent(
      eventLink
    )}`
    window.open(shareUrl, "_blank")
  }

  const shareOnTwitter = () => {
    const shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      eventLink
    )}`
    window.open(shareUrl, "_blank")
  }

  return (
    <Modal
      {...props}
      size="md"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          className="text-center"
          as={"div"}
        >
          Share with friends
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pl-2">
        <Row className="text-center pb-5 pt-12">
          <Col>
            <span
              variant="light"
              className="rounded-circle p-3 pointer"
              onClick={shareOnFacebook}
            >
              <BsFacebook
                color="#4267B2"
                className="mb-1"
                size={20}
                title="Facebook"
              />
            </span>
          </Col>
          <Col>
            <span
              variant="light"
              className="rounded-circle p-3 pointer"
              onClick={shareOnTwitter}
            >
              <RiTwitterXLine
                className="mb-1"
                size={20}
                title="Twitter"
              />
            </span>
          </Col>

          <Col>
            <span
              variant="light" // Set the button to use a light (white) background
              className="rounded-circle p-3 pointer"
              onClick={shareOnMessenger}
            >
              <BsMessenger
                color="#006AFF"
                className="mb-1"
                size={20}
                title="Facebook Messenger"
              />
            </span>
          </Col>
        </Row>
        <Form.Label htmlFor="basic-url">Link</Form.Label>
        <InputGroup
          size="md"
          className="mb-3"
        >
          <Row>
            <Col>
              <InputGroup.Text slot="12">{eventLink}</InputGroup.Text>
            </Col>

            <Col>
              {" "}
              <FaCopy
                size={26}
                color={isCopied ? "#d43790" : "#000000"}
                className="ml-4"
                style={{
                  cursor: "pointer",
                  color: "primary",
                }}
                onClick={copyToClipboard}
                onMouseEnter={() => setIsCopied(false)}
              />
            </Col>
          </Row>
        </InputGroup>
      </Modal.Body>
      <Modal.Footer className="text-center">
        {isCopied && "copied"}
      </Modal.Footer>
    </Modal>
  )
}

export default ShareButtonModal
