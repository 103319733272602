import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { apiRequest } from "../requests"
import QRCode from "qrcode"
import {
  Image,
  PDFViewer,
  Text,
  View,
  Document,
  Page,
} from "@react-pdf/renderer"
import { mmToPdfPoints, truncateTextDynamically } from "../utils/functions"
import { constants } from "../constants"
import { LoadingView } from "../utils/components"
import { PointDivider } from "../PageComponents/PhysicalTickets"

const IMAGE_URLS = {}
const QR_WIDTH = mmToPdfPoints(15)

function StickersPDFPage() {
  useEffect(() => {
    getInfo()
  }, [])

  const [isLoading, setIsLoading] = useState(false)
  const [qrImage, setQrImage] = useState(null)
  const [totalArray, setTotalArray] = useState([])
  const [event, setEvent] = useState({})
  const [tickets, setTickets] = useState([])
  const [wristbandInfo, setWristbandInfo] = useState(null)
  const { orderItemId } = useParams()

  const getInfo = async () => {
    setIsLoading(true)

    const { success, response } = await apiRequest.get(
      `api/physical/tickets/${orderItemId}`
    )
    if (success) {
      setEvent(response.event)
      response.tickets.forEach(async (ticket) => {
        IMAGE_URLS[ticket.id] = await QRCode.toDataURL(ticket.reference_id)
      })
      setTickets(response.tickets)
    }
    setIsLoading(false)
  }
  return (
    <LoadingView
      isLoading={isLoading}
      view={
        <div className={"text-center"}>
          <PDFViewer
            width={window.innerWidth}
            height={window.innerHeight}
          >
            <Document>
              {tickets.map((ticket, index) => {
                return (
                  <Page
                    key={index}
                    size={{
                      width: mmToPdfPoints(50.8),
                      height: mmToPdfPoints(25.4),
                    }}
                    style={{
                      backgroundColor: "#ffffff",
                      // paddingHorizontal: 3,
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        height: mmToPdfPoints(20),
                      }}
                    >
                      <View
                        style={{
                          width: mmToPdfPoints(16),
                          justifyContent: "cesnter",
                          alignItems: "center",
                        }}
                      >
                        <Image
                          src={IMAGE_URLS[ticket.id]}
                          style={{
                            width: QR_WIDTH,
                            height: QR_WIDTH,
                            padding: 0,
                            margin: 0,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: 8,
                            fontWeight: "bold",
                            textAlign: "center",
                            marginTop: -3,
                          }}
                        >
                          {ticket.extra_id.replace("VTX-PHY-", "")}
                          {"\n"}#{index + 1}
                        </Text>
                      </View>
                      <View
                        style={{
                          width: mmToPdfPoints(34.8),
                          paddingTop: mmToPdfPoints(1),
                          paddingRight: 1,
                        }}
                      >
                        <Text style={{ fontSize: 9, fontWeight: "bold" }}>
                          {truncateTextDynamically(
                            event.name,
                            9,
                            mmToPdfPoints(34.4),
                            2
                          )}
                        </Text>
                        <PointDivider height={1} />
                        <Text style={{ fontSize: 8 }}>{ticket.name}</Text>
                        <PointDivider height={1} />
                        <Text style={{ fontSize: 8 }}>{ticket.price}</Text>
                      </View>
                    </View>
                    <View
                      style={{
                        height: mmToPdfPoints(5.4),
                        width: mmToPdfPoints(50.8),
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 8,
                          // fontWeight: "bold",
                          textAlign: "center",
                        }}
                        s
                      >
                        {constants.contacts}
                      </Text>
                    </View>
                  </Page>
                )
              })}
            </Document>
          </PDFViewer>
        </div>
      }
    />
  )
}

export default StickersPDFPage
