import {Container} from "react-bootstrap";

export default function Footer() {
	return (
		<Container className={'py-5 text-center'} id={'footer'}>
			<h6 className={'text-muted'}>
				© {new Date().getUTCFullYear()}, Ventix, a LEXURY GROUP LLC Product
			</h6>
			<p className={'mt-3'}>
				<a href={"/tos"} className={'text-primary'}>
					Terms of Service
				</a>
				<span className={'mx-2'}> | </span>
				<a href={"/privacy"} className={'text-primary'}>
					Privacy Policy
				</a>
			</p>
		</Container>
	)
}