import React from "react"
import { View, Text, Image, StyleSheet } from "@react-pdf/renderer"
import { constants } from "../../../constants"
import { Divider, PointDivider } from "../../PhysicalTickets"
import { mmToPdfPoints, mmToPixels } from "../../../utils/functions"

const QR_WIDTH = mmToPdfPoints(17)

export const WRISTBAND_DIMENSIONS = {
  adhesive_1: {
    desc: "[Adhesive] First wristbands with adhesive",
    height: 25.4, // in mm
    total_width: 279.4,
    width_1: 68,
    width_2: 88.9,
    width_3: 122.5,
  },
  adhesive_2: {
    desc: "[Adhesive] wristbands w/ Ventix logo",
    showLines: true,
    height: 25, // in millimeters per wristband
    total_width: 254,
    width_1: 165,
    width_2: 89,
    width_3: 0,
    width_2_1: 25.4,
    width_2_2: 63.6,
    width_1_1: 135,
    width_1_2: 30,
  },
  // button: {
  //   desc: "[Button[ Wristbands with buttons",
  //   height: 120, // in pixels
  //   total_width: 1104,
  //   width_1: 10,
  //   width_2: 350,
  //   width_3: 744,
  // },
}

// Define styles
const styles = StyleSheet.create({
  centerAll: {
    justifyContent: "center",
    alignItems: "center",
  },
  centerVertically: {
    flex: 1,
    justifyContent: "center",
  },
  oneLineContainer: {
    width: 236, // set the width to your desired value
    overflow: "hidden",
  },
  oneLineText: {
    whiteSpace: "nowrap",
  },
})

// Define component
const WristbandTicket = ({
  ticket,
  event,
  position,
  index,
  seat,
  qrImageUrl,
  dimensions,
  printData,
}) => {
  let showLine = index % printData.perRoll === 0 && dimensions.showLines

  return (
    <View
      style={{
        flexDirection: "row",
        height: mmToPdfPoints(dimensions.height),
        borderBottomWidth: showLine ? 1 : 0,
        borderBottomColor: "#000000",
      }}
    >
      <View
        style={{
          flexDirection: "row",
          width: mmToPdfPoints(dimensions.width_1),
        }}
      >
        <View style={{ width: mmToPdfPoints(dimensions.width_1_1) }}>
          <PointDivider height={2} />
        </View>
      </View>
      <View
        style={{
          width: mmToPdfPoints(dimensions.width_2),
          flexDirection: "row",
          // paddingLeft: mmToPdfPoints(10),
        }}
      >
        <View
          style={[
            {
              width: mmToPdfPoints(dimensions.width_2_1),
              // justifyContent: "center",
            },
            styles.centerAll,
          ]}
        >
          {seat && (
            <View style={{ marginBottom: 0 }}>
              <Text style={{ fontSize: 8 }}>{`SEAT ${seat}`}</Text>
            </View>
          )}
          <Image
            src={qrImageUrl}
            style={{ width: QR_WIDTH, height: QR_WIDTH, padding: 0, margin: 0 }}
          />

          <Text
            style={{
              fontSize: 8,
              fontWeight: "bold",
              textAlign: "center",
              marginTop: -3,
            }}
          >
            {ticket.extra_id.replace("VTX-PHY-", "")} #{index + 1}
          </Text>
        </View>
        <View
          style={[
            {
              width: mmToPdfPoints(dimensions.width_2_2),
              marginLeft: mmToPdfPoints(1),
            },
            styles.centerVertically,
          ]}
        >
          {/*<PointDivider height={0.5} />*/}
          <Text style={{ fontSize: 10, fontWeight: "bold" }}>{event.name}</Text>
          <PointDivider height={1} />
          <Text style={{ fontSize: 8 }}>{event.start_datetime}</Text>
          <PointDivider height={1} />
          <View style={styles.oneLineContainer}>
            <Text style={[{ fontSize: 8 }, styles.oneLineText]}>
              {`${event.full_address}`.slice(0, 44)}
              {event.full_address.length > 44 ? "..." : ""}
            </Text>
          </View>
          <PointDivider height={1} />
          <Text style={{ fontSize: 8 }}>
            {ticket.name}
            {ticket.is_public ? ` | ${ticket.price}` : ""}
            {ticket.multiplier === 1
              ? ""
              : ` | ${position} of ${ticket.multiplier}`}
          </Text>
          <PointDivider height={1} />
          <Text style={{ fontSize: 9, textAlign: "left" }}>
            {constants.contacts}
          </Text>
        </View>
      </View>
      <View style={{ width: mmToPdfPoints(dimensions.width_3) }}>
        <Divider />
      </View>
    </View>
  )
}

export default WristbandTicket
