import { Container } from "react-bootstrap"
import Accordion from "react-bootstrap/Accordion"

export default function FAQs({ questions }) {
  return (
    <Container className="py-4">
      <h2 className={"text-center mb-5"}>Frequently Asked Questions</h2>
      <Accordion
        className="bg-primary"
        defaultActiveKey="0"
      >
        {questions.map((item, index) => (
          <Accordion.Item
            eventKey={index.toString()}
            key={index}
          >
            <Accordion.Header>{item.question}</Accordion.Header>
            <Accordion.Body>{item.details}</Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </Container>
  )
}
