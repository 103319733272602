import { Container, Image } from "react-bootstrap"
import cardImg from "../../assets/images/contactless.png"
import { Slide } from "react-awesome-reveal"

export default function ContactlessCard() {
  return (
    <div className="container-bkg mt-5">
      <div id={"text-white"}>
        <div className="row px-2 ">
          <div className="col-md-6 mr-2 pt-5">
            <Slide direction={"left"}>
              <Image
                fluid={true}
                src={cardImg}
              />
            </Slide>
          </div>
          <div className="col-md-6 px-5 text-white my-auto">
            <Slide direction={"right"}>
              <h1>Contactless Card</h1>

              <p className="fs-6  justify-content-center">
                Introducing the Contactless Card with NFC Support – a
                revolutionary way to make purchases with just a tap. Utilizing
                NFC technology, this card offers unparalleled convenience and
                security. Simply wave your card near the phone to make fast and
                secure transactions without the need to swipe or insert. With
                advanced encryption and fraud detection features, your financial
                information is safeguarded at all times
              </p>
            </Slide>
          </div>
        </div>
      </div>
    </div>
  )
}
