import React from "react"
import { JackInTheBox } from "react-awesome-reveal"

import { Container, Card, Row, Col } from "react-bootstrap"
import {
  FaMoneyBillWaveAlt,
  FaMobileAlt,
  FaShieldAlt,
  FaReceipt,
} from "react-icons/fa"

function IconCard({ icon: Icon, title, description }) {
  return (
    <Card className="shadow-lg pt-5 bg-primary text-white  mt-5 h-80">
      <Card.Body className="text-center flex flex-col justify-between">
        <div className="mt-3">
          <Icon
            size={64}
            style={{ color: "white" }}
          />
          <Card.Title className="mt-2">{title}</Card.Title>
        </div>
        <Card.Text className="py-4">{description}</Card.Text>
      </Card.Body>
    </Card>
  )
}

function CashAccountBenefit() {
  const benifitsLists = [
    {
      icon: FaMoneyBillWaveAlt, // Replace with the desired icon from react-icons or another library
      title: "Easy Money Management",
      description:
        "Track your income, expenses, and account balance in real-time.",
    },
    {
      icon: FaMobileAlt, // Replace with the desired icon from react-icons or another library
      title: "Convenient Mobile Access",
      description: "Manage your account on the go, anytime, anywhere.",
    },
    {
      icon: FaReceipt, // Replace with the desired icon from react-icons or another library
      title: "Bill Pay and Transfers",
      description:
        "Easily pay your bills and transfer money directly from your account.",
    },
    {
      icon: FaShieldAlt, // Replace with the desired icon from react-icons or another library
      title: "Secure Transactions",
      description:
        "Enjoy peace of mind with industry-standard security features.",
    },
  ]

  return (
    <div className="container-fluid bg-blue-400 mt-8">
      <Container>
        <h2 className="text-center py-4">Benefits</h2>
        <JackInTheBox>
          <Row className="py-3 mb-5 justify-content-center">
            {benifitsLists.map((cardItem, cardIndex) => (
              <Col
                xs={12}
                sm={6}
                md={6}
                lg={3}
                key={cardIndex}
              >
                <IconCard {...cardItem} />
              </Col>
            ))}
          </Row>
        </JackInTheBox>
      </Container>
    </div>
  )
}

export default CashAccountBenefit
