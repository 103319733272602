import {Col, Container, Row} from "react-bootstrap";
import {BsFacebook, BsEnvelope, BsMessenger} from 'react-icons/bs'

export default function Contact() {

	function openLink(link) {
		window.open(link, "_blank")
	}

	return (
		<div className={'contact-bkg'}>
			<Container className={'pb-5 pt-3'} id={'contact'}>
				<Row>
					<Col sm={'3'}>
					</Col>
					<Col sm={'6'} className={'text-center'}>
						<h1>
							Contact Us
						</h1>
						<p>
							Get in touch with us using one of the following contact methods and we will be happy to answer any
							questions
							you may have or assist you with anything.
						</p>
						<Row className={'text-center pt-4'}>
							<Col>
								<span className={'pointer'} onClick={() => openLink('https://fb.me/ventixApp')}>
									<BsFacebook color={'#4267B2'} className={'mb-1'} size={70} title={'Facebook'}/>
									<h5>Facebook</h5>
								</span>
							</Col>
							<Col>
								<span className={'pointer'} onClick={() => openLink('mailto:info@getventix.com')}>
									<BsEnvelope color={'#D43790'} className={'mb-1'} size={70} title={'Email'}/>
									<h5>Email</h5>
								</span>
							</Col>
							<Col>
								<span className={'pointer'} onClick={() => openLink('https://m.me/ventixApp')}>
									<BsMessenger color={'#006AFF'} className={'mb-1'} size={70} title={'Facebook Messenger'}/>
									<h5>Facebook Messenger</h5>
								</span>
							</Col>
						</Row>
					</Col>
					<Col sm={'3'}>
					</Col>
				</Row>
			</Container>
		</div>
	)
}