export function ConditionalView({ condition, trueView, falseView }) {
  if (condition) {
    return <>{trueView}</>
  } else {
    return <>{falseView}</>
  }
}

export function LoadingView({ isLoading, view, text = "Loading..." }) {
  return (
    <ConditionalView
      condition={isLoading}
      trueView={
        <div className={"text-center mt-5"}>
          <br />
          <br />
          <div
            className="spinner-border text-primary"
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
          <h5 className={"mt-3"}>{text}</h5>
        </div>
      }
      falseView={<>{view}</>}
    />
  )
}

export function DisplayText({ text }) {
  return <span style={{ whiteSpace: "pre-line" }}>{text}</span>
}
