import React from "react"
import NavBar from "../PageComponents/NavBar"
import { Button, Card, Col, Container, Row } from "react-bootstrap"
import { useParams } from "react-router-dom"

function DeepLinkRedirectPage() {
  const path = window.location.pathname
  const endpoint = path.replace("/app/", "")

  return (
    <div>
      <Col className={"bg-secondary bg-opacity-25"}>
        <NavBar />
        <Container className={""}>
          <Row>
            <Col
              md={{ span: 7, offset: 3 }}
              className="text-center mt-5"
            >
              <Card className={"pb-5"}>
                <Card.Header className={"py-3 bg-primary text-white"}>
                  <h1>Ventix App Link</h1>
                </Card.Header>
                <Card.Body className={"px-3"}>
                  <h4 className={"mt-5 pt-5"}>
                    This ia a Ventix app link. Tap the button below to open it
                    in the Ventix app.
                  </h4>
                  <Button
                    className={"my-5 w-100 text-white py-3"}
                    variant={"primary"}
                    onClick={() => {
                      window.location.href = `ventix://${endpoint}`
                    }}
                  >
                    Open in the Ventix App
                  </Button>
                </Card.Body>
                <Card.Footer className={"px-3"}>
                  <h4>If you don't have the app, you can download it below.</h4>
                  <Button
                    className={"my-3 w-100 text-white py-3"}
                    variant={"dark"}
                    onClick={() => {
                      window.location.href = "https://getventix.com/app"
                    }}
                  >
                    Download the Ventix App
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Container>
      </Col>
    </div>
  )
}

export default DeepLinkRedirectPage
