import React, { useEffect, useState } from "react"
import {
  Document,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
  Image,
} from "@react-pdf/renderer"
import { apiRequest } from "../requests"
import logo from "../assets/images/physicalTicketLogo.png"
import QRCode from "qrcode"
import { LoadingView } from "../utils/components"
import { useParams } from "react-router-dom"
import { mmToPdfPoints, mmToPixels } from "../utils/functions"

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#ffffff",
  },
  eventInfo: {
    marginBottom: 5,
    paddingHorizontal: 8,
    flexDirection: "row",
  },
  eventHeading: {
    fontSize: 16,
  },
  eventText: {
    fontSize: 12,
  },
  centerAll: {
    justifyContent: "center",
    alignItems: "center",
  },
})

const IMAGE_URLS = {}

export function Divider(props) {
  return (
    <View
      style={{
        height: props.h || 10,
        // backgroundColor: props.c || Colors.trans,
        width: props.w || "100%",
      }}
    />
  )
}
export function PointDivider({ height = 2, width = "100%" }) {
  // height in millimeters
  return (
    <View
      style={{
        height: mmToPdfPoints(height),
        width: width,
      }}
    />
  )
}

// Create Document Component
const Ticket = ({ tickets, event }) => (
  <Document>
    {tickets.map((ticket, i) => {
      const index = i + 1
      const diff = index % ticket.multiplier
      const position = diff === 0 ? ticket.multiplier : diff

      return (
        <Page
          key={i}
          size={{ width: 528, height: 192 }}
          style={styles.page}
        >
          <View style={[{ width: 96 }, styles.centerAll]}>
            <Image
              src={logo}
              style={{ width: 80 }}
            />
          </View>
          <View style={{ width: 432, marginLeft: 8, marginTop: 10 }}>
            {/*<Divider h={10} />*/}
            <Text
              style={{
                fontSize: 16,
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              {event.organizer_name}
            </Text>
            <Divider />
            <View style={[styles.eventInfo]}>
              <View style={{ width: 336 }}>
                <Text style={styles.eventHeading}>{event.name}</Text>
                <Divider />
                <Text style={styles.eventText}>{event.datetime}</Text>
                <Divider />
                <Text style={styles.eventHeading}>{event.full_address}</Text>
                <Divider h={20} />
                <Text style={{ fontSize: 14, textAlign: "center" }}>
                  {ticket.name}
                  {ticket.is_public ? ` | ${ticket.price}` : ""}
                  {ticket.multiplier === 1
                    ? ""
                    : ` | ${position} of ${ticket.multiplier}`}
                </Text>
              </View>
              <View style={[{ width: 96 }, styles.centerAll]}>
                <Image
                  src={IMAGE_URLS[ticket.id]}
                  style={{ width: 90, height: 90 }}
                />
                <Text style={{ fontSize: 16 }}>{ticket.reference_id}</Text>
                <Divider h={5} />
                <Text style={{ fontSize: 8 }}>
                  {ticket.extra_id} #{i + 1}
                </Text>
              </View>
            </View>
          </View>
        </Page>
      )
    })}
  </Document>
)

export default function PhysicalTickets() {
  useEffect(() => {
    getData()
  }, [])
  const [isLoading, setIsLoading] = useState(false)
  const [tickets, setTickets] = useState([])
  const [event, setEvent] = useState({})
  const { orderItemId } = useParams()

  const getData = async () => {
    setIsLoading(true)
    const { success, response } = await apiRequest.get(
      `api/physical/tickets/${orderItemId}`
    )
    if (success) {
      setEvent(response.event)
      response.tickets.forEach(async (ticket) => {
        IMAGE_URLS[ticket.id] = await QRCode.toDataURL(ticket.reference_id)
        setTickets(response.tickets)
      })
    }
    setIsLoading(false)
  }

  if (tickets.length === 0) {
    return (
      <LoadingView
        isLoading={isLoading}
        view={<div />}
      />
    )
  }
  return (
    <LoadingView
      isLoading={isLoading}
      view={
        <div className={"text-center"}>
          <PDFViewer
            width={window.innerWidth}
            height={window.innerHeight}
          >
            <Ticket
              tickets={tickets}
              event={event}
            />
          </PDFViewer>
        </div>
      }
    />
  )
}
