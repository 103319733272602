import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { LoadingView } from "../utils/components"
import { apiRequest } from "../requests"
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Modal,
  Navbar,
  Row,
} from "react-bootstrap"
import NavBar from "./NavBar"
import { Helmet } from "react-helmet"
import VotingPeriodListItem from "./components/Event/VotingPeriodListItem"
import ContestantListItem from "./components/Event/ContestantListItem"
import OrderInfoRetrivalForm from "./forms/Events/OrderInfoRetrivalForm"
import AppModal from "../components/AppModal"
import { constants } from "../constants"
import { isEmpty, updateObjectState } from "../utils/functions"
import { FaSearch } from "react-icons/fa"

function VotingPeriodPage() {
  useEffect(() => {
    getPeriod()
  }, [])

  const [orderInfo, setOrderInfo] = useState({
    number: null,
    emailOrPhone: null,
    amount: null,
    votes: 0,
    requested: false,
  })
  const [data, setData] = useState({
    votes_count: 1,
  })
  const [filterTerm, setFilterTerm] = useState("")
  const [modal, setModal] = useState({})
  const [showVoteModal, setShowVoteModal] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [period, setPeriod] = useState({
    contestants: [],
  })
  const [contestant, setContestant] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const { periodId } = useParams()

  const getPeriod = async () => {
    setIsLoading(true)
    const { success, response } = await apiRequest.get(
      `api/event/voting/period/${periodId}`
    )
    if (success) {
      setPeriod({
        ...response.period,
        contestants: response.contestants,
      })
    } else {
      setModal({
        title: "Error",
        body: response.message || constants.unknownErrorMsg,
      })
      setShowModal(true)
    }
    setIsLoading(false)
  }

  const submitOrderInfo = async () => {
    setIsLoading(true)
    const { success, response } = await apiRequest.post(
      `api/event/voting/period/${periodId}/order-info`,
      orderInfo
    )
    if (success) {
      setOrderInfo(response.order_info)
    } else {
      setModal({
        title: "Error",
        body: response.message || constants.unknownErrorMsg,
      })
      setShowModal(true)
    }
    setIsLoading(false)
  }

  const submitVote = async () => {
    setIsLoading(true)
    setShowVoteModal(false)
    const { success, response } = await apiRequest.post(
      `api/event/voting/period/${periodId}/vote/${contestant.id}`,
      {
        votes_count: data.votes_count,
        order_info: orderInfo,
      }
    )
    let title = "Error"
    if (success) {
      title = "Vote Successful"
      setOrderInfo(response.order_info)
      setData({ votes_count: 1 })
    }
    setModal({
      title: title,
      body: response.message || constants.unknownErrorMsg,
    })
    setShowModal(true)
    setIsLoading(false)
  }

  return (
    <div className={"bg-secondary bg-opacity-25"}>
      <NavBar />
      <LoadingView
        isLoading={isLoading}
        view={
          <div>
            {period?.id && (
              <Helmet>
                <title>{`Vote - ${period.name}`}</title>
                <meta
                  property="og:title"
                  content={period.name}
                />
                <meta
                  property="og:description"
                  content={period.description}
                />
                <meta
                  property="og:url"
                  content={"https://getventix.com/voting/" + period.id}
                />
              </Helmet>
            )}

            <div className={"container my-3"}>
              <VotingPeriodListItem
                period={period}
                clickable={false}
              />
              <Row className={"mt-3"}>
                <Col md={4}>
                  <Card>
                    <Card.Header>
                      <Card.Title
                        as={"h4"}
                        className={"my-3"}
                      >
                        Your Votes
                      </Card.Title>
                      <Card.Subtitle
                        as={"p"}
                        className={"mt-3"}
                      >
                        Enter your order number, email / phone number and order
                        amount from your order confirmation to retrieve your
                        votes
                      </Card.Subtitle>
                      <Card.Body className={"mt-3"}>
                        {orderInfo.requested && (
                          <div className={"text-center"}>
                            <h4 className={"text-primary"}>
                              Order # <br />
                              VTX-{orderInfo.number}
                            </h4>
                            <p className={"text-muted"}>{orderInfo.contact}</p>

                            <h1 className={"text-center"}>{orderInfo.votes}</h1>
                            <p className={"mb-4"}>Votes Available</p>

                            <p>
                              Click on the "Vote for" button on a contestants
                              card to vote for them
                            </p>
                          </div>
                        )}
                        {!orderInfo.requested && (
                          <div>
                            <OrderInfoRetrivalForm
                              orderInfo={orderInfo}
                              setOrderInfo={setOrderInfo}
                              onSubmit={() => {
                                submitOrderInfo()
                              }}
                            />
                          </div>
                        )}
                      </Card.Body>
                    </Card.Header>
                  </Card>
                </Col>
                <Col md={8}>
                  <div>
                    <div className={"my-3"}>
                      <InputGroup>
                        <InputGroup.Text>
                          <FaSearch />
                        </InputGroup.Text>
                        <Form.Control
                          type="text"
                          placeholder="Search Contestants"
                          value={filterTerm}
                          onChange={(e) => {
                            setFilterTerm(e.target.value)
                          }}
                        />
                      </InputGroup>
                    </div>
                    {period?.contestants?.map((contestant, index) => {
                      if (
                        !isEmpty(filterTerm) &&
                        !contestant.title
                          .toLowerCase()
                          .includes(filterTerm.toLowerCase())
                      )
                        return <div />
                      return (
                        <div
                          key={contestant.id}
                          className={"mb-3"}
                        >
                          <ContestantListItem
                            contestant={contestant}
                            index={index}
                            period={period}
                            availableVotes={orderInfo.votes}
                            voteCallback={(contestant) => {
                              setContestant(contestant)
                              setShowVoteModal(true)
                            }}
                          />
                        </div>
                      )
                    })}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        }
      />

      <AppModal
        modal={modal}
        setShowModal={setShowModal}
        showModal={showModal}
      />

      <Modal
        show={showVoteModal}
        onHide={() => {
          setShowVoteModal(false)
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{`Vote for ${contestant.title}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className={"text-center mb-4"}>
              <h1>{orderInfo.votes - data.votes_count}</h1>
              <p className={"mb-4"}>Available Votes</p>

              <Form
                onSubmit={(e) => {
                  e.preventDefault()
                }}
              >
                <InputGroup>
                  <Button
                    type={"button"}
                    variant="outline-primary"
                    disabled={data.votes_count === 1}
                    onClick={() => {
                      if (data.votes_count === 1) return
                      const newQty = data.votes_count - 1
                      updateObjectState(setData, {
                        votes_count: newQty,
                      })
                    }}
                  >
                    -1 Vote
                  </Button>
                  <Form.Control
                    readOnly={true}
                    className={"text-center fs-3"}
                    placeholder="Votes"
                    value={data.votes_count}
                  />
                  <Button
                    type={"button"}
                    className={"text-white"}
                    variant="primary"
                    onClick={() => {
                      const newCount = data.votes_count + 1
                      if (newCount > orderInfo.votes) return

                      updateObjectState(setData, {
                        votes_count: newCount,
                      })
                    }}
                  >
                    +1 Vote
                  </Button>
                </InputGroup>
              </Form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={"py-4"}>
          <Button
            className={"w-100"}
            variant="primary"
            onClick={() => {
              submitVote()
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default VotingPeriodPage
