import React from "react"
import appStore from "../../assets/images/app-store.png"
import playStore from "../../assets/images/play-store.png"
import ventixWallet from "../../assets/images/ventixWallet.png"
import { Container, Image } from "react-bootstrap"
import { downLoadVisit } from "../../analytics"

function Header({ source }) {
  return (
    <div
      className={"walletinfo container-bkg py-5"}
      id={"wallet"}
    >
      <Container className={"text-white"}>
        <div className="row">
          <div className="col-md-6 pt-5 my-auto">
            <h1>Experience the ultimate fusion</h1>
            <p>
              Your Complete Cash Solution – seamlessly integrating your cash
              account, cutting-edge contactless card, and virtual U$D card into
              one powerful package
            </p>
            <div className={"row pt-4"}>
              <p>
                <Image
                  className={"pointer"}
                  fluid={false}
                  src={playStore}
                  height={"auto"}
                  width={"150px"}
                  onClick={() => downLoadVisit("android", source)}
                />
                <span className={"mx-2"}></span>
                <Image
                  className={"pointer"}
                  fluid={false}
                  src={appStore}
                  height={"auto"}
                  width={"150px"}
                  onClick={() => downLoadVisit("ios", source)}
                />
              </p>
            </div>
          </div>
          <div className="col-md-6 pt-5">
            <div>
              <Image
                fluid={true}
                src={ventixWallet}
                height={2400}
                width={2400}
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Header
