import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import PrivacyPolicy from "./PageComponents/PrivacyPolicy"
import TermsOfService from "./PageComponents/TermsOfService"
import OrganizerAgreement from "./PageComponents/OrganizerAgreement"
import Event from "./PageComponents/Event"
import EventsPage from "./PageComponents/EventsPage"
import MainApp from "./MainApp"
import AppDownload from "./PageComponents/AppDownload"
import PhysicalTickets from "./PageComponents/PhysicalTickets"
import WristbandsPdf from "./PageComponents/WristbandsPdf"
import CompletePayment from "./PageComponents/CompletePayment"
import TriviaInfo from "./PageComponents/TriviaInfo"
import UnsubscribePage from "./pages/UnsubscribePage"
import VotingPeriodPage from "./PageComponents/VotingPage"
import WalletInfo from "./PageComponents/WalletInfo/Wallet"
import DeepLinkRedirectPage from "./pages/WalletAccess"
import StickersPDFPage from "./pages/StickersPDFScreen"

export default function App() {
  return (
    <Router>
      <div className="bg-white">
        <Switch>
          <Route
            path="/"
            component={MainApp}
            exact
          />
          <Route
            path="/tos"
            component={TermsOfService}
            exact
          />
          <Route
            path="/privacy"
            component={PrivacyPolicy}
            exact
          />
          <Route
            path="/organizer-agreement"
            component={OrganizerAgreement}
            exact
          />
          <Route
            path="/app"
            component={AppDownload}
            exact
          />
          <Route
            path="/event/:eventCode"
            component={Event}
            exact
          />
          <Route
            path="/wallet"
            component={WalletInfo}
            exact
          />
          <Route
            path="/all-events/:eventCategory"
            component={EventsPage}
            exact
          />
          <Route
            path="/app/home/event/:eventCode"
            component={Event}
            exact
          />
          <Route
            path="/physical/tickets/:orderItemId"
            component={PhysicalTickets}
            exact
          />
          <Route
            path="/physical/wristbands/:orderItemId"
            component={WristbandsPdf}
            exact
          />
          <Route
            path="/physical/stickers/:orderItemId"
            component={StickersPDFPage}
            exact
          />
          <Route
            path="/complete/payment/:paymentId"
            component={CompletePayment}
            exact
          />
          <Route
            path="/trivia"
            component={TriviaInfo}
            exact
          />
          <Route
            path="/unsubscribe"
            component={UnsubscribePage}
            exact
          />
          <Route
            path="/voting/:periodId"
            component={VotingPeriodPage}
            exact
          />
          <Route
            path="/app/*"
            component={DeepLinkRedirectPage}
            exact
          />
          {/*<Route path="/.well-known/apple-app-site-association" component={IosUniversalLink} exact/>*/}
        </Switch>
      </div>
    </Router>
  )
}
