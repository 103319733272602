import FAQs from "../../components/FAQs"

export default function WalletFAQs() {
  const questions = [
    {
      question: "How to Place order for Contactless Card?",
      details:
        "Please access your Cash Account and choose the final option labelled 'Your Cash Card.' Ensure that the specified amount is available in your Cash Account before proceeding with the card order. Our commitment to prompt customer service means your card will be swiftly prepared and ready for pickup immediately after your order. Feel free to collect it at our location: 42e Water Street, Congo Cross, Freetown. Thank you for selecting our services; we appreciate your trust in us.",
    },
    {
      question: "The Importance of the Card?",
      details:
        "The Cash Account facilitates rapid fund transfers to both your Cash Account and Orange/Afrimoney. On the other hand, the Visa Virtual USD Card empowers you to seamlessly convert your Leones from your Cash Account into USD, enabling online payments for various goods and services. We also have the Contactless Card, to support your Cash Account. This Card can only be used to transfer funds to Ventix Cash Account Users, in a case where you might not have your phone with you or the phone battery is dead. Thanks for choosing our service.",
    },
    {
      question:
        "Trying to create my Cash Account but I’m stuck at entering my Pin Code?",
      details:
        "Please swipe to go up and you’ll see an option to enter your username.",
    },
    {
      question: "How to withdraw money from my Cash Account?",
      details:
        "We highly values the efforts of Orange/Afrimoney and their commendable work. As a result, our platform does not support physical cash withdrawals. Instead, we offer the convenient option for you to transfer funds from your cash account to either Orange/Afrimoney, allowing you to easily perform cash withdrawals through these reputable mobile money services. We appreciate your collaboration in advancing the digital economy with us. Thank you for joining us on this transformative journey.",
    },
    {
      question:
        "I have made my Order for the Contactless Card but I’m not in Freetown?",
      details:
        "We understood your situation and it’s okay if you want to send someone to collect your Contactless Card. Please make sure you share your Username on your Cash Account with the individual or a screenshot of your Cash Account. Kindly confirm with the individual to bring along an Identification Card with them.",
    },
    {
      question: "Can I use the Ventix Virtual USD Card to receive money?",
      details:
        "Kindly note that our Virtual Visa USD Card is exclusively designed for online transactions, facilitating seamless payments and subscriptions for a wide array of goods and services.",
    },
    {
      question: "How to make online payment with the Virtual Card?",
      details:
        "When configuring your Virtual USD Card, take advantage of the billing address feature. This designated address becomes your primary payment information for online subscriptions like Spotify and similar services, enhancing the convenience of your digital transactions with an additional layer of security and organisation.",
    },
    {
      question:
        "Agreeing to the terms, I want to understand why the above amount for the Virtual Card?",
      details:
        "To complete the setup process, a total of Nle 854.00 (USD 36.00) is required in your Cash Account. Nle 600 (USD 25.00) will be allocated to your Virtual Card as your balance, while Nle 264.00 (USD 11.00) will serve as Our setup fee for providing you with our service. We trust that the above information is clear and helpful. Should you have any inquiries or require further assistance, please feel free to reach out to us via our Customer Line at +232 73/80 778877. We are here to assist you.",
    },
  ]

  return (
    <>
      <FAQs questions={questions} />
    </>
  )
}
