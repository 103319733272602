import React from "react"
import { Button, Form, InputGroup } from "react-bootstrap"
import { isEmpty } from "../../../utils/functions"

function OrderInfoRetrivalForm({ orderInfo, setOrderInfo, onSubmit }) {
  return (
    <div>
      <Form
        onSubmit={(e) => {
          e.preventDefault()
        }}
      >
        <Form.Label>Order Number</Form.Label>
        <InputGroup className="mb-4">
          <InputGroup.Text id="orderNum">VTX-</InputGroup.Text>
          <Form.Control
            type="number"
            placeholder="Enter order number"
            value={orderInfo.number || ""}
            onChange={(e) =>
              setOrderInfo({ ...orderInfo, number: e.target.value })
            }
          />
        </InputGroup>

        <Form.Group className="mb-4">
          <Form.Label>Email / Phone Number</Form.Label>
          <Form.Control
            type="text"
            placeholder="e.g. +23233112233 or joe@gmil.com"
            value={orderInfo.emailOrPhone || ""}
            onChange={(e) =>
              setOrderInfo({ ...orderInfo, emailOrPhone: e.target.value })
            }
          />
          <Form.Text>
            The email or full phone number we sent the order confirmation to.
            Including the country code.
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4">
          <Form.Label>Total Amount Paid for the Order</Form.Label>
          <Form.Control
            type="number"
            placeholder="e.g. 100.12"
            value={orderInfo.amount || ""}
            onChange={(e) =>
              setOrderInfo({ ...orderInfo, amount: e.target.value })
            }
          />
          <Form.Text>The total amount you paid for the order</Form.Text>
        </Form.Group>

        <Button
          variant="primary"
          className={"w-100"}
          disabled={
            isEmpty(orderInfo.number) ||
            isEmpty(orderInfo.emailOrPhone) ||
            isEmpty(orderInfo.amount)
          }
          onClick={onSubmit}
        >
          Submit
        </Button>
      </Form>
    </div>
  )
}

export default OrderInfoRetrivalForm
