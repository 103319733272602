import { useEffect, useState } from "react"
// import './App.scss';
import NavBar from "./PageComponents/NavBar"
import Header from "./PageComponents/Header"
import "./index.css"
import ReactGA from "react-ga"
import HowItWorks from "./PageComponents/HowItWorks"
import Why from "./PageComponents/Why"
import EventTypes from "./PageComponents/EventTypes"
import Contact from "./PageComponents/Contact"
import Footer from "./PageComponents/Footer"
import Tutorials from "./PageComponents/Tutorials"
import UpcomingEventsContainer from "./PageComponents/UpcomingEventsContainer"
import { constants } from "./constants"

ReactGA.initialize("UA-161757367-7")

function App() {
  useEffect(() => {
    getSource()
  }, [])

  const [source, setSource] = useState(null)

  const getSource = (vals) => {
    const urlParams = new URLSearchParams(window.location.search)
    const ref = urlParams.get("ref")

    ReactGA.event({
      action: "Page Visit",
      category: ref,
      label: ref + " visitor",
      value: 1,
    })
    setSource(ref || "None")
  }

  return (
    <div>
      <div>
        <NavBar mainApp={true} />
        <div>
          <Header source={source} />
          <HowItWorks />
          <Why />
          {/*<Tutorials />*/}
          {/* <EventTypes source={source} /> */}
          {/* All Events  */}
          <UpcomingEventsContainer
            eventCategory={"Events"}
            containerId={"upcomingEvents"}
            bgColor={"#E5E4E2"}
            title={"Upcoming Events"}
            subtitle={
              "Browse our schedule of upcoming events, and secure your tickets online now for unforgettable experiences"
            }
          />
          {/* Only Services */}
          <UpcomingEventsContainer
            eventCategory={"Services"}
            containerId={"services"}
            bgColor={"#FFF"}
            title={"Services"}
            subtitle={`Explore our range of services and simplify your payments with just a few clicks`}
          />
          {/* Only Voting */}
          <UpcomingEventsContainer
            eventCategory={"Voting"}
            containerId={"voting"}
            bgColor={"#F8F8F8"}
            title={"Voting"}
            subtitle={`Our seamless digital voting for engaging events facilitates global participation. Utilize mobile wallet, credit, or debit card options to effortlessly purchase votes`}
          />
          <Contact />
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default App
