import { Button, Col, Container, Modal, Row } from "react-bootstrap"
import { ConditionalView, LoadingView } from "../utils/components"
import { useEffect, useState } from "react"
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai"
import { useParams } from "react-router-dom"
import NavBar from "./NavBar"
import { apiRequest } from "../requests"

export default function CompletePayment() {
  useEffect(() => {
    verifyPayment()
  }, [])
  const { paymentId } = useParams()

  const [verified, setVerified] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState(
    "Your payment has been completed. We will send you an email."
  )

  const verifyPayment = async () => {
    setIsLoading(true)
    const { success, response } = await apiRequest.post(
      `api/event/pay-verify`,
      {
        payment_transaction_id: paymentId,
      }
    )
    setVerified(success)
    setMessage(response.message)
    setIsLoading(false)
  }

  return (
    <div className={"bg-secondary bg-opacity-25"}>
      <NavBar />
      <LoadingView
        isLoading={isLoading}
        view={
          <Container className={"my-3 py-5 text-center bg-white"}>
            <ConditionalView
              condition={verified}
              trueView={
                <div>
                  <AiFillCheckCircle
                    size={150}
                    className={"text-success fs-1"}
                  />
                </div>
              }
              falseView={
                <div>
                  <AiFillCloseCircle
                    size={150}
                    className={"text-danger fs-1"}
                  />
                </div>
              }
            />
            <br />
            <h4>{message}</h4>
          </Container>
        }
      />
    </div>
  )
}
