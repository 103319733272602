import { Container } from "react-bootstrap"
import { useEffect, useState } from "react"
import { LoadingView } from "../utils/components"
import { useParams } from "react-router-dom"
import NavBar from "./NavBar"
import { sectionSpace } from "../constants"
import EventList from "./EventList"
import { fetchEvents } from "../components/fetchEvents"
import InfiniteScroll from "../components/InfiniteScroll" // Import the InfiniteScroll component

function EventsPage() {
  // Retrieve the eventCategory parameter from the route
  const { eventCategory } = useParams()

  // State management for events and pagination
  const [events, setEvents] = useState([])
  const [page, setPage] = useState(1)
  const [perPage] = useState(20)
  const [hasNext, setHasNext] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [loadedPages, setLoadedPages] = useState([])

  // Function to load more events when the InfiniteScroll component triggers it
  const loadMore = () => {
    if (hasNext && !loadedPages.includes(page + 1)) {
      setIsLoading(true)

      // Fetch the next page of events
      fetchEvents(eventCategory, page + 1, perPage)
        .then((fetchedEvents) => {
          setEvents((prevEvents) => [...prevEvents, ...fetchedEvents.events])
          setHasNext(fetchedEvents.paging_info.has_next)
          setIsLoading(false)
          setPage(page + 1)
          setLoadedPages((prevLoadedPages) => [prevLoadedPages, page + 1])
        })
        .catch((error) => {
          setIsLoading(false)
        })
    }
  }

  // Fetch the initial page of events when the component is mounted
  useEffect(() => {
    setIsLoading(true)

    fetchEvents(eventCategory, page, perPage)
      .then((fetchedEvents) => {
        setEvents(fetchedEvents.events)
        setHasNext(fetchedEvents.paging_info.has_next)
        setIsLoading(false)
        setLoadedPages([page])
      })
      .catch((error) => {
        setIsLoading(false)
      })
  }, [])

  // Function to open links in a new tab/window
  function openLink(link) {
    window.open(link, "_blank")
  }

  return (
    <div className={"bg-secondary bg-opacity-25"}>
      <NavBar />

      <Container
        className={"pb-5 pt-3 mr-20 ml-20"}
        style={{ overflowY: "scroll" }}
      >
        <div className={sectionSpace} />

        {/* Render the EventList component if there are events to display */}
        {events?.length > 0 && (
          <EventList
            events={events}
            title={eventCategory}
            eventCategory={eventCategory}
            openLink={openLink}
          />
        )}

        {/* Render the InfiniteScroll component for continuous scrolling */}
        {hasNext && (
          <InfiniteScroll
            onLoadMore={loadMore}
            hasMore={hasNext}
          />
        )}

        {/* Render a loading indicator when new events are being fetched */}
        <LoadingView
          isLoading={isLoading}
          view={""}
        />
      </Container>
    </div>
  )
}

export default EventsPage
