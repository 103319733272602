import React from "react"
import appStore from "../assets/images/app-store.png"
import playStore from "../assets/images/play-store.png"
import headerImage from "../assets/images/headerImg.png"
import { Container, Image } from "react-bootstrap"
import { downLoadVisit } from "../analytics"

function Header({ source }) {
  return (
    <div
      className={"header-bkg"}
      id={"home"}
    >
      <Container className={"text-white"}>
        <div className="row">
          <div className="col-md-6 my-auto">
            <h1>Digitize Your Events</h1>
            <p>
              Ventix is the easiest way to sell tickets, manage crowds and keep
              revenue. With the Ventix app, event organizers can start selling
              verifiable tickets to the public in just a few taps. People can
              discover events near them and purchase tickets with their mobile
              money accounts, credit or debit cards.
            </p>
            <div className={"row pt-4"}>
              <p>
                <Image
                  className={"pointer"}
                  fluid={false}
                  src={playStore}
                  height={"auto"}
                  width={"150px"}
                  onClick={() => downLoadVisit("android", source)}
                />
                <span className={"mx-2"}></span>
                <Image
                  className={"pointer"}
                  fluid={false}
                  src={appStore}
                  height={"auto"}
                  width={"150px"}
                  onClick={() => downLoadVisit("ios", source)}
                />
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <Image
                fluid={true}
                src={headerImage}
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Header
