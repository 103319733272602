import { apiRequest } from "../requests"

export async function fetchEvents(eventCategory, page = 1, perPage = 4) {
  try {
    if (!eventCategory || typeof eventCategory !== "string") {
      throw new Error("Invalid event category.")
    }

    const endpoint = `api/events?page=${page}&perPage=${perPage}&category=${eventCategory}`
    const response = await apiRequest.get(endpoint)

    if (response.success) {
      return response.response || []
    } else {
      return null
    }
  } catch (error) {
    return null
  }
}
