import { Button, Modal } from "react-bootstrap"

function AppModal({ modal, showModal, setShowModal, actionCallback }) {
  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>{modal.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>{modal.body}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setShowModal(false)
          }}
        >
          {!modal.interactive ? "Ok" : "No"}
        </Button>
        {modal.interactive && (
          <Button
            variant="primary"
            onClick={() => {
              actionCallback()
            }}
          >
            Yes
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default AppModal
