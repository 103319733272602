import { Col } from "react-bootstrap"

const ErrorMessage = ({ content }) => {
  return (
    <Col
      sm={12}
      className="text-center"
    >
      <strong>{`We are sorry there is no ${content} available at the moment.
      Plaase contact us for any enquires`}</strong>
    </Col>
  )
}

export default ErrorMessage
