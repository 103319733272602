import {useEffect} from "react";
import {downLoadVisit} from "../analytics";
import {constants} from "../constants";

let loaded = false

export default function AppDownload() {
	useEffect(() => {
		const userAgent = navigator.userAgent || navigator.vendor || window.opera;
		if(loaded){
			return;
		}
		loaded = true

		if (/windows phone/i.test(userAgent)) {

		} else if (/android/i.test(userAgent)) {
			downLoadVisit('android', false, false, false)
			window.location.href = constants.androidUrl
			return
		} else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			downLoadVisit('ios', false,  false, false)
			window.location.href = constants.iosUrl
			return
		}
		window.location.href = constants.linksUrl
	}, [])
}